import { createSlice } from "@reduxjs/toolkit";
import {
  cancelRide,
  fetchTripDetail,
  fetchTripList,
} from "../action/tripAction";
import { setBookingData } from "../../utils/pip";

const initialState = {
  isLoading: false,
  tripList: [],
  tripDetail: {},
};

export const tripSlice = createSlice({
  name: "Trips",
  initialState: initialState,
  reducers: {
    handleTripDetail: (state, action) => {
      state.tripDetail = action?.payload;
    },
  },
  extraReducers: (builder) => {
    // fetch-card-list
    builder.addCase(fetchTripList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTripList.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.tripList = data ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchTripList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-trip-detail
    builder.addCase(fetchTripDetail.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchTripDetail.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      setBookingData(data);

      state.tripDetail = data ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchTripDetail.rejected, (state, action) => {
      state.isLoading = false;
    });

    // cancel-trip-details
    builder.addCase(cancelRide.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(cancelRide.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(cancelRide.rejected, (state, action) => {
      state.isLoading = false;
    });
  },
});

export const { handleTripDetail } = tripSlice.actions;
export default tripSlice.reducer;
