import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import { pipCardDateView } from "../utils/pip";
import Loader from "../components/other/Loader";
import { pageRoutes } from "../routes/pageRoutes";
import SubHeadings from "../components/SubHeadings";
import NoRecord from "../components/other/NoRecord";
import { useDispatch, useSelector } from "react-redux";
import { deleteCard, fetchCardList } from "../redux/action/cardAction";

const PaymentMehtod = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isLoading, cards } = useSelector((state) => state.cardReducer);
  const [methodId, setMethodId] = useState();
  useEffect(() => {
    dispatch(fetchCardList());
  }, []);

  const handleDeleteCard = (id) => {
    const callback = (response) => {
      if (response.success) {
        dispatch(fetchCardList());
      }
    };
    dispatch(
      deleteCard({
        payload: {
          card_id: methodId,
        },
        callback,
      })
    );
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="ct_profile_form pb-5">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="ct_fs_24 ct_fw_600">
                            Payment Methods
                          </h4>
                          <a
                            href="javascript:void(0)"
                            className="ct_green_btn ct_py_6 ct_fs_16"
                            onClick={() =>
                              navigate(pageRoutes.add_payment_method)
                            }
                          >
                            + Add Card
                          </a>
                        </div>
                        <div className="table-responsive">
                          <table className="table ct_custom_table">
                            <thead>
                              <tr>
                                <th>Card Holder Name</th>
                                <th>Exp. Date</th>
                                <th>Card Number</th>
                                <th className="text-end">Actions</th>
                              </tr>
                            </thead>
                            <tbody>
                              {cards?.length > 0 ? (
                                cards?.map((item, index) => (
                                  <tr>
                                    <td>{item?.card_holder_name}</td>
                                    <td>
                                      {pipCardDateView(item?.card_expiry)}
                                    </td>
                                    <td>{item?.card_number}</td>
                                    <td>
                                      <div className="ct_action_btns justify-content-end">
                                        <a
                                          onClick={() =>
                                            navigate(
                                              pageRoutes.edit_payment_method,
                                              { state: { data: item } }
                                            )
                                          }
                                          href="javascript:void(0)"
                                          className="ct_edit_btn"
                                        >
                                          <i className="fa-solid fa-pencil"></i>
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          className="ct_delete_btn"
                                          data-bs-toggle="modal"
                                          data-bs-target="#ct_confirmation_modal"
                                          onClick={() => setMethodId(item?.id)}
                                        >
                                          <i className="fa-solid fa-trash"></i>
                                        </a>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <NoRecord />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />

      {/* DELETE PAYMENT METHOD CONFIRMATION */}
      <div
        className="modal fade ct_assets_modal"
        id="ct_confirmation_modal"
        tabIndex="-1"
        aria-labelledby="ct_logout_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pt-0">
              <button
                type="button"
                className="btn-close ct_cloose_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body border-0 ">
              <h4 className="text-center mb-4 ct_fw_600">Delete Card</h4>
              <p className="text-center ct_grey_text">
                Are you sure, you want to delete this Card?
              </p>
              <div className="modal-footer border-0 justify-content-center">
                <button
                  type="button"
                  className="ct_green_btn ct_out_line_btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  href="javascript:void(0)"
                  type="button"
                  data-bs-dismiss="modal"
                  className="bg-danger ct_green_btn justify-content-center"
                  style={{ borderColor: "rgb(220, 53, 69)" }}
                  onClick={handleDeleteCard}
                >
                  Delete
                </a>
              </div>
              <div></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMehtod;
