import React, { useState } from "react";
import DatePicker from "react-multi-date-picker";
import TimePicker from "react-multi-date-picker/plugins/time_picker";

const DateHourInput = ({ onChange, format = "MM/DD/YYYY HH:mm", ...props }) => {
  const [selectedDate, setSelectedDate] = useState(null);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    onChange && onChange(date); // Call onChange prop if provided
  };

  return (
    <div>
      <DatePicker
        format={format}
        value={selectedDate}
        onChange={handleDateChange}
        plugins={[<TimePicker position="bottom" hideSeconds/>]}
        {...props}
      />
    </div>
  );
};

export default DateHourInput;
