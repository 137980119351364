import React, { useEffect, useRef } from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

const CarSlider = () => {
  const options = {
    loop: true,
    animateIn: "fadeIn",
    margin: 10,
    autoplayTimeout: 3000,
    nav: true,
    autoplay: 3000,
    mouseDrag: false,
    touchDrag: false,
    slideSpeed: 2000,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  return (
    <div className="row mt-5 pt-5">
      <div className="col-md-12">
        <OwlCarousel
          className="owl-carousel owl-theme ct_login_slider"
          {...options}
        >
          <div className="item">
            <div className="ct_slider_item">
              <div
                className="ct_gradient_box"
                style={{
                  background:
                    "linear-gradient(180deg, #0B0000 0%, #666060 100%)",
                }}
              ></div>
              <div className="ct_card_item_animated">
                <h1 className="ct_fs_64 ct_fw_800 text-white">Sedan</h1>
                <img src="../assets/img/Black_sedan_img.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="ct_slider_item">
              <div
                className="ct_gradient_box"
                style={{
                  background:
                    " linear-gradient(180deg, #0B0000 0%, #666060 100%)",
                }}
              ></div>
              <div className="ct_card_item_animated">
                <h1 className="ct_fs_64 ct_fw_800 text-white">SUV</h1>
                <img src="../assets/img/Black_Suv.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="ct_slider_item">
              <div
                className="ct_gradient_box"
                style={{
                  background:
                    "linear-gradient(180deg, #0B0000 0%, #666060 100%)",
                }}
              ></div>
              <div className="ct_card_item_animated">
                <h1 className="ct_fs_64 ct_fw_800 text-white">Luxury Sedan</h1>
                <img src="../assets/img/image_313.png" alt="" />
              </div>
            </div>
          </div>
          <div className="item">
            <div className="ct_slider_item">
              <div
                className="ct_gradient_box"
                style={{
                  background:
                    "linear-gradient(180deg, #0B0000 0%, #666060 100%)",
                }}
              ></div>
              <div className="ct_card_item_animated">
                <h1 className="ct_fs_64 ct_fw_800 text-white">SUV</h1>
                <img src="../assets/img/Suv_Black.png" alt="" />
              </div>
            </div>
          </div>
        </OwlCarousel>
      </div>
    </div>
  );
};

export default CarSlider;
