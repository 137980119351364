import { API_REQUEST } from ".";
import {
  getCarsAPI,
  getLatLongAPI,
  getLocationByAddressAPI,
  getNotificationsAPI,
  selectCarAPI,
  selectLocationAPI,
} from "../../routes/apiEndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";

// select-location
export const selectLocation = createAsyncThunk(
  "select-location",
  async (props) => {
    try {
      const { payload, callback } = props ?? {};
      const response = await API_REQUEST({
        url: selectLocationAPI,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {}
  }
);

// fetch-car-list
export const fetchCarList = createAsyncThunk("fetch-car-list", async (props) => {
  try {
    const response = await API_REQUEST({
      url: getCarsAPI,
      method: "POST",
      data:props,
      isSuccessToast : false,
    });
    return response;
  } catch (error) {}
});

// select-car
export const selectCar = createAsyncThunk("select-car", async (props) => {
  try {
    const { payload, callback } = props ?? {};
    const response = await API_REQUEST({
      url: selectCarAPI,
      method: "POST",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {}
});

    // fetch-notification
export const fetchNotification = createAsyncThunk("fetch-notification", async () => {
  try {
    const response = await API_REQUEST({
      url: getNotificationsAPI,
      method: "GET",
    });
    return response;
  } catch (error) {}
});

// fetch-locations-list
export const fetchLocationList = createAsyncThunk("fetch-locations-list", async (props) => {
  try {
    const params = {}
    params.input = props
    const response = await API_REQUEST({
      url: getLocationByAddressAPI,
      method: "GET",
      params
    });
    return response;
  } catch (error) {}
});
// fetch-lat-long
export const fetchLatLong = createAsyncThunk("fetch-lat-long", async (props) => {
  try {
    const {callback,payload} = props;
    const params = {}
    params.address = payload
    const response = await API_REQUEST({
      url: getLatLongAPI,
      method: "GET",
      params
    });
    callback(response)
    return response;
  } catch (error) {}
});