import Cookies from "js-cookie";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Headers from "../components/Headers";
import Input from "../components/form/Input";
import { useNavigate } from "react-router-dom";
import CarSlider from "../components/CarSlider";
import Loader from "../components/other/Loader";
import { pageRoutes } from "../routes/pageRoutes";
import { Formik } from "formik";
import { signInSchema } from "../utils/schema";
import ErrorMessage from "../components/form/ErrorMessage";
import Eye from "../components/Eye";
import { authLogin } from "../redux/action/authAction";

const Login = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEye, setIsEye] = useState(false);
  const [isChecked, setIsChecked] = useState(false);
  const { isLoading } = useSelector((state) => state.authReducer);
  const initialState = {
    email: Cookies.get("email-address") ?? "",
    password: Cookies.get("pass-word" ?? ""),
  };

  const handleLogin = async (values) => {
    if (isChecked) {
      Cookies.set("email-address", values?.email, { expires: 365 });
      Cookies.set("pass-word", values?.password, { expires: 365 });
    }
    const callback = (response) => {
      if (response.success) navigate(pageRoutes?.dashboard);
    };
    dispatch(authLogin({ payload: values, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Headers /> 
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_login_main_cnt">
                <h2 className="ct_fs_78 text-center ct_ff_orbitron ct_fw_700">
                  Luxury is waiting for you
                </h2>
                <Formik
                  initialValues={initialState}
                  validationSchema={signInSchema}
                  onSubmit={(values) => {
                    handleLogin(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} className="mb-3">
                      <div className="row">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="email"
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Email *
                            </label>
                            <Input
                              placeholder="email"
                              id="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="password"
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Password *
                            </label>
                            <div className="position-relative">
                              <div className="position-relative">
                                <Input
                                  placeholder="password"
                                  id="password"
                                  onChange={handleChange}
                                  onBlur={handleBlur}
                                  value={values.password}
                                  type={isEye ? "text" : "password"}
                                />
                                <Eye isEye={isEye} onClick={setIsEye} />
                              </div>
                              <ErrorMessage
                                errors={errors}
                                touched={touched}
                                fieldName="password"
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label className="mb-2" htmlhtmlFor="loginButton">
                            &nbsp;
                          </label>
                          <button
                            type="button"
                            id="loginButton"
                            className="ct_green_btn w-100 ct_h_40"
                            onClick={handleSubmit}
                          >
                            Login
                          </button>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-between ct_flex_column_991 ct_flex_column_767 gap-3">
                        <div className="form-check form-check-inline ct_custom_check mt-3 d-flex align-items-center">
                          <input
                            id="inlineCheckbox1"
                            onChange={() => setIsChecked(!isChecked)}
                            type="checkbox"
                            className="ct_custom_checkbox ct_ff_roboto"
                            checked={isChecked}
                          />
                          <label
                            className="form-check-label ct_fs_16 ct_fw_400 ms-1" style={{whiteSpace: 'nowrap'}}
                            htmlFor="inlineCheckbox1"
                          >
                            Remember Me
                          </label>
                        </div>
                        <p className="ct_fs_18  mb-0 text-center">
                          <span className="ct_text_op_5">
                            Not Registered Yet?
                          </span>{" "}
                          <a
                            href="javascrip:void(0)"
                            onClick={() => {
                              navigate(pageRoutes?.signup);
                            }}
                            className="ct_text_dark_green ct_fw_600"
                          >
                            Create an account
                          </a>
                        </p>
                        <div>
                          <a
                            href="javascrip:void(0)"
                            onClick={() => {
                              navigate(pageRoutes?.forgotPassword);
                            }}
                            className="text-dark ct_fw_600"
                          >
                            Forgot Password?
                          </a>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <CarSlider />
        </div>
      </section>
    </>
  );
};

export default Login;
