import { message, message as toast } from "antd";
import moment from "moment";

// Currency Symbol
export const curSym = "$";

// Authorizationw
export const pipSetAccessToken = (token) => {
  if (!token) return;
  localStorage.setItem("access-token", token);
};
export const pipGetAccessToken = () => {
  return localStorage.getItem("access-token");
};
export const clearAuth = () => {
  localStorage.removeItem("access-token");
  localStorage.removeItem("users_data");
  localStorage.removeItem("booking-data");
  localStorage.removeItem("typeofLogin");
  message.success("Successfully logged out");
  window.location.reload()
};

// Bokking data
export const setBookingData = (data) => {
  if (!data) return;
  localStorage.setItem("booking-data", JSON.stringify(data));
};
export const getBookingData = () => {
  const bookingData = localStorage.getItem("booking-data") ;
  return bookingData ? JSON.parse(bookingData) : {};
};

// Profile
export const pipSaveProfile = (profile) => {
  if (!profile) return;
  else localStorage.setItem("users_data", JSON.stringify(profile));
};

export const pipGetProfile = () => {
  const profile = localStorage.getItem("users_data");
  return profile ? JSON.parse(profile) : {};
};


// Square Profile
export const pipSaveSquareProfile = (profile) => {
  if (!profile) return;
  else localStorage.setItem("square-profile", JSON.stringify(profile));
};

export const pipGetSquareProfile = () => {
  const profile = localStorage.getItem("square-profile");
  return profile ? JSON.parse(profile) : {};
};


// Date View Format
export const pipViewDate = (date) => {
  if (!date) return;
  return moment(date).format("DD-MM-YYYY");
};

// Message Handlers
export const pipSuccessMessage = (message) => {
  if (!message) return;
  return toast.success(message);
};

export const pipErrorMessage = (message) => {
  if (!message) return;
  return toast.error(message);
};

export const pipCardDateView = (date) => {
  if (!date) return;
  return moment(date, "YYYY-MM-DD").format("MM/YY");
};

// Ride time view
export const pipRideTimeView = (time) => {
  if (!time) return;
  return moment(time).format("ddd, MMM Do, YYYY hh:mm A");
};

export const getSubstring = (str, len, appendStr = "...") => {
  if(!str) return "";
  if (str?.length <= len) return str;
  return str?.substring(0, len) + appendStr;
};