import { Formik } from "formik";
import Eye from "../components/Eye";
import "react-phone-number-input/style.css";
import Headers from "../components/Headers";
import Input from "../components/form/Input";
import { signUpSchema } from "../utils/schema";
import { useNavigate } from "react-router-dom";
import Loader from "../components/other/Loader";
import CarSlider from "../components/CarSlider";
import { pageRoutes } from "../routes/pageRoutes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { authRegister } from "../redux/action/authAction";
import ErrorMessage from "../components/form/ErrorMessage";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";

const SignUp = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isEye, setIsEye] = useState(false);
  const [isEye1, setIsEye1] = useState(false);
  const [isShowError, setShowError] = useState(false);
  const { isLoading } = useSelector((state) => state.authReducer);

  const initialState = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
    password: "",
    confirmPassword: "",
  };

  const handleSignUp = async (values) => {
    if (!isValidPhoneNumber(values?.mobile_number)) {
      return setShowError(true);
    }

    const callback = (response) => {
      if (response.success) navigate(pageRoutes.login);
    };
    const { confirmPassword, ...payload } = values;
    dispatch(authRegister({ payload, callback }));
  };

  useEffect(() => {
    if (
      isPossiblePhoneNumber(initialState?.mobile_number) ||
      isValidPhoneNumber(initialState?.mobile_number)
    ) {
      setShowError(false); // Do not show error initially if the number is valid
    } else {
      setShowError(true);
    }
  }, [initialState?.mobile_number]);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Headers />
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_login_main_cnt">
                <h2 className="ct_fs_78 text-center ct_ff_orbitron ct_fw_700">
                  Luxury is waiting for you
                </h2>
                <Formik
                  initialValues={initialState}
                  validationSchema={signUpSchema}
                  onSubmit={(values, actions) => {
                    handleSignUp(values, actions);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form className="mt-3" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              First Name *
                            </label>
                            <Input
                              placeholder="first name"
                              id="first_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.first_name}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="first_name"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              Last Name *
                            </label>
                            <Input
                              placeholder="last name"
                              id="last_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.last_name}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="last_name"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              Phone<span>*</span>
                            </label>
                            <div className="">
                              <PhoneInput
                               international
                                className="form-control "
                                defaultCountry="CO"
                                placeholder="Please enter phone number"
                                onChange={(value) => {
                                  setFieldValue("mobile_number", value ?? "");
                                  if (
                                    !isPossiblePhoneNumber(
                                      values?.mobile_number
                                    ) ||
                                    !isValidPhoneNumber(values?.mobile_number)
                                  )
                                    setShowError(true);
                                  else setShowError(false);
                                }}
                                value={values?.mobile_number}
                                error={
                                  values?.mobile_number
                                    ? isValidPhoneNumber(values?.mobile_number)
                                      ? undefined
                                      : "Invalid phone number"
                                    : "Phone number required"
                                }
                              />
                            </div>

                            {isShowError && (
                              <span style={{ color: "red" }}>
                                {
                                  !isValidPhoneNumber(values?.mobile_number) &&
                                  "Please enter a valid phone number"}
                              </span>
                            )}
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              Email*
                            </label>
                            <Input
                              placeholder="email"
                              id="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              Password *
                            </label>
                            <div className="position-relative">
                              <Input
                                placeholder="password"
                                id="password"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.password}
                                type={isEye ? "text" : "password"}
                              />
                              <Eye isEye={isEye} onClick={setIsEye} />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="password"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                              Confirm Password *
                            </label>
                            <div className="position-relative">
                              <Input
                                placeholder="confirm password"
                                id="confirmPassword"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.confirmPassword}
                                type={isEye1 ? "text" : "password"}
                              />
                              <Eye isEye={isEye1} onClick={setIsEye1} />
                            </div>
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="confirmPassword"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4 mx-auto">
                          <label className="mb-2">&nbsp;</label>
                          <button
                            type="button"
                            className="ct_green_btn w-100 ct_h_40"
                            onClick={handleSubmit}
                          >
                            Sign up
                          </button>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center ct_flex_column_767 gap-3">
                        <p className="ct_fs_18  mb-0 text-center">
                          <span className="ct_text_op_5">
                            Already registered?
                          </span>{" "}
                          <a
                            href="javascrip:void(0)"
                            onClick={() => navigate(pageRoutes?.login)}
                            className="ct_text_dark_green ct_fw_600"
                          >
                            Login
                          </a>
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <CarSlider />
        </div>
      </section>
    </>
  );
};

export default SignUp;
