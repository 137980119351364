import React from 'react'

const NoRecord = () => {
  return (
    <h3 className='text-center'>
      No record found.
    </h3>
  )
}

export default NoRecord
