import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./reducers/authReducer";
import cardReducer from "./reducers/cardReducer";
import tripReducer from "./reducers/tripReducer";
import bookingReducer from "./reducers/bookingReducer";

const store = configureStore({
  reducer: {
    authReducer,
    cardReducer,
    tripReducer,
    bookingReducer,
  },
});

export default store;
