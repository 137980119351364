import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import SubHeadings from "../components/SubHeadings";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../components/other/Loader";
import { fetchNotification } from "../redux/action/bookingAction";
import NoRecord from "../components/other/NoRecord";

const Notifications = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { Loading, notifications } = useSelector(
    (state) => state.bookingReducer
  );

  useEffect(() => {
    dispatch(fetchNotification());
  }, []);

  if (Loading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              data-aos="fade-down "
              data-aos-duration="1000"
            >
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="ct_profile_form pb-5">
                        <div className="d-flex align-items-center justify-content-between mb-4">
                          <h4 className="ct_fs_24 ct_fw_600">Notifications</h4>
                        </div>
                        <div className="ct_notification_card_main">
                          <ul>
                            {notifications?.length > 0 &&
                              notifications?.map((notification, index) => (
                                <li key={index}
                              
                                >
                                  <p className="mb-0 ct_fw_500">
                                    {notification.notification_message}
                                  </p>
                                  <a
                                    href="javascript:void(0)"
                                    onClick={() => {
                                        navigate(pageRoutes.trip_details,{state:{id:notification?.booking?.id}})
                                    }}
                                    className="ct_green_btn ct_fs_16 py-2 ct_out_line_btn"
                                  >
                                    See Details
                                  </a>
                                </li>
                              ))}
                              {
                                notifications?.length <=0 && <>
                                <NoRecord/>
                                </>
                              }
                          
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Notifications;
