import React, { useState } from "react";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import SubHeadings from "../components/SubHeadings";
import { pipGetProfile } from "../utils/pip";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { Formik } from "formik";
import { updateProfileSchema } from "../utils/schema";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../routes/pageRoutes";
import ErrorMessage from "../components/form/ErrorMessage";
import Input from "../components/form/Input";
import { updateProfile } from "../redux/action/authAction";
import { useDispatch } from "react-redux";

const UpdateProfile = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { first_name, last_name, mobile_number } = pipGetProfile();
  const [isShowError, setShowError] = useState(false);

  const initialState = {
    first_name: first_name ?? "",
    last_name: last_name ?? "",
    mobile_number: mobile_number ?? "",
  };

  const handleSubmitProfileData = async (values) => {
   
    if (!isValidPhoneNumber(values?.mobile_number)) {
      setShowError(true);
      return;
    }

    const callback = (response) => {
      if (response.success) navigate(pageRoutes?.my_profile);
    };

    dispatch(updateProfile({ payload: values, callback }));
  };
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <div className="ct_profile_form pb-5">
                        <h4 className="ct_fs_24 ct_fw_600 mb-4">
                          Update Profile
                        </h4>
                        <Formik
                          initialValues={initialState}
                          validationSchema={updateProfileSchema}
                          onSubmit={(values) => {
                            handleSubmitProfileData(values);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      First Name <span>*</span>
                                    </label>
                                    <Input
                                      placeholder="first name"
                                      id="first_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.first_name}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      fieldName="first_name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Last Name<span>*</span>
                                    </label>
                                    <Input
                                      placeholder="last name"
                                      id="last_name"
                                      onChange={handleChange}
                                      onBlur={handleBlur}
                                      value={values.last_name}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      fieldName="last_name"
                                    />
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Phone<span>*</span>
                                    </label>

                                    <div className="">
                                      <PhoneInput
                                        international
                                        className="form-control "
                                        defaultCountry="CO"
                                        placeholder="Please enter phone number"
                                        onChange={(value) => {
                                          setFieldValue(
                                            "mobile_number",
                                            value ?? ""
                                          );
                                          if (
                                            !isPossiblePhoneNumber(
                                              values?.mobile_number
                                            ) ||
                                            !isValidPhoneNumber(
                                              values?.mobile_number
                                            )
                                          )
                                            setShowError(true);
                                          else setShowError(false);
                                        }}
                                        value={values?.mobile_number}
                                        error={
                                          values?.mobile_number
                                            ? isValidPhoneNumber(
                                                values?.mobile_number
                                              )
                                              ? null
                                              : "Invalid phone number"
                                            : "Phone number required"
                                        }
                                      />
                                    </div>

                                    {isShowError && (
                                      <span style={{ color: "red" }}>
                                        {!isValidPhoneNumber(
                                          values?.mobile_number
                                        ) &&
                                          "Please enter a valid phone number"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="button"
                                  className="ct_green_btn w-100"
                                  onClick={handleSubmit}
                                >
                                  Save
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default UpdateProfile;
