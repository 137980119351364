import { API_REQUEST } from ".";
import {
  addCardAPI,
  getCardAPI,
  deleteCardAPI,
  updateCardAPI,
  selectCardDetailAPI,
  confirmBookingAPI,
} from "../../routes/apiEndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";

// add-card
export const addCard = createAsyncThunk("add-card", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: addCardAPI,
      method: "POST",
      data: payload,
      isErrorToast: true,
    });
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});
// update-card
export const updateCard = createAsyncThunk("update-card", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: updateCardAPI,
      method: "POST",
      data: payload,
      isErrorToast: true,
    });
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});

// fetch-card-list
export const fetchCardList = createAsyncThunk("fetch-card-list", async () => {
  try {
    const response = await API_REQUEST({
      url: getCardAPI,
      method: "GET",
    });
    return response;
  } catch (error) {}
});

// delete-card
export const deleteCard = createAsyncThunk("delete-card", async (props) => {
  const { payload, callback } = props;
  try {
    const response = await API_REQUEST({
      url: deleteCardAPI,
      method: "DELETE",
      data: payload,
    });
    callback(response);
    return response;
  } catch (error) {
    callback(null, error);
  }
});

// select-card-detail
export const selectCardDetail = createAsyncThunk(
  "select-card-detail",
  async (props) => {
    try {
      const response = await API_REQUEST({
        url: selectCardDetailAPI,
        method: "POST",
        data: props,
        isErrorToast: true,
        isSuccessToast: false,
      });

      return response;
    } catch (error) {}
  }
);

// confirm-booking
export const confirmBooking = createAsyncThunk(
  "confirm-booking",
  async (props) => {
    const { payload, callback } = props;
    try {
      const response = await API_REQUEST({
        url: confirmBookingAPI,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {
      callback(null, error);
    }
  }
);
