import React from "react";
import { useNavigate } from "react-router";
import { pageRoutes } from "../routes/pageRoutes";

const NavHeading = ({ path, onClick }) => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12">
          <ul className="nav nav-pills ct_profile_tab justify-content-center">
            <li
              className="nav-item"
              role="presentation"
              onClick={() => onClick("Standard")}
            >
              <a
                href="javascript:void(0)"
                className={`nav-link ${
                  path == "Standard" && "active"
                } text-center`}
                type="button"
              >
                Standard
              </a>
            </li>
            <li className="nav-item" onClick={() => onClick("Premium")}>
              <a
                href="javascript:void(0)"
                className={`nav-link ${
                  path == "Premium" && "active"
                } text-center`}
                type="button"
              >
                Premium
              </a>
            </li>
            <li
              className="nav-item"
              role="presentation"
              onClick={() => onClick("Executive")}
            >
              <a
                href="javascript:void(0)"
                className={`nav-link ${
                  path == "Executive" && "active"
                } text-center`}
                type="button"
              >
                Executive
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default NavHeading;
