import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { toggleChange } from "../redux/reducers/authReducer";
import { clearAuth, pipGetAccessToken } from "../utils/pip";

const Headers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const pathname = useLocation().pathname;
  const { isToggle } = useSelector((state) => state?.authReducer);

  return (
    <>
      <div className="ct_header">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <header>
                <div
                  className="ct_logo"
                  onClick={() => navigate(`${pageRoutes.dashboard}`)}
                >
                  <img
                    src="assets/logo/logoChar.png"
                    className="ct_pointer_curser"
                    alt=""
                  />
                </div>
                {/* HEADER: NAV BAR TOP
                <nav className={isToggle ? "active" : ""}>
                  {pathname == pageRoutes?.dashboard && (
                    <ul>
                      <div
                        className="ct_close_bar"
                        onClick={() => dispatch(toggleChange(false))}
                      >
                        <i className="fa-solid fa-xmark"></i>
                      </div>
                      <li>
                        <a
                          // href="#howitwork"
                          href="javascript:void(0)"
                          onClick={() => {
                            window.scrollTo({
                              top: 630 * 2, // Vertical position
                              left: 0, // Horizontal position
                              behavior: "smooth", // Enables smooth scrolling
                            });
                          }}

                          // href="javascript:void(0)"
                          // onClick={() => {
                          //   const targetSection = document.getElementById("howitwork");
                          //   if (targetSection) {
                          //     targetSection.scrollIntoView({
                          //       behavior: "smooth", // Smooth scrolling
                          //       block: "start" // Aligns the top of the section with the top of the screen
                          //     });
                          //   }
                          // }}
                        >
                          How it work
                        </a>
                      </li>
                      <li>
                        <a href="#whychooseus">Why choose us</a>
                      </li>
                      <li>
                        <a href="#reviews">Reviews </a>
                      </li>
                      <li>
                        <a
                          href=""
                          onClick={() => {
                            navigate(pageRoutes?.dashboard);
                          }}
                        >
                          Book Your trip
                        </a>
                      </li>
                    </ul>
                  )}
                </nav> */}
                {pipGetAccessToken() ? (
                  <div className="ct_right_header">
                    <ul>
                      <li onClick={() => navigate(pageRoutes.my_profile)}>
                        <a href="javscript:void(0)" className="ct_circle_40">
                          <i className="fa-regular fa-user"></i>
                        </a>
                      </li>
                      <li>
                        <a
                          href="javscript:void(0)"
                          className="ct_circle_40"
                          data-bs-toggle="modal"
                          data-bs-target="#ct_logout_modal"
                        >
                          <div className="ct_log_out_btn">
                            <i className="fa-solid fa-power-off"></i>
                          </div>
                        </a>
                      </li>
                      {pathname == pageRoutes?.dashboard && (
                        <li>
                          <div
                            className="ct_bar_icon"
                            onClick={() => dispatch(toggleChange(true))}
                          >
                            <i className="fa-solid fa-bars"></i>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                ) : (
                  <div className="ct_right_header">
                    <ul>
                      <li>
                        <a
                          href="#"
                          className={
                            pageRoutes.login == pathname ? "active" : ""
                          }
                          onClick={() => {
                            navigate(pageRoutes?.login);
                          }}
                        >
                          Login
                        </a>
                      </li>

                      <li>
                        <a
                          href="#"
                          className={
                            pageRoutes.signup == pathname ? "active" : ""
                          }
                          onClick={() => {
                            navigate(pageRoutes?.signup);
                          }}
                        >
                          Sign up
                        </a>
                      </li>
                      {pathname == pageRoutes?.dashboard && (
                        <li>
                          <div
                            className="ct_bar_icon"
                            onClick={() => dispatch(toggleChange(true))}
                          >
                            <i className="fa-solid fa-bars"></i>
                          </div>
                        </li>
                      )}
                    </ul>
                  </div>
                )}
              </header>
            </div>
          </div>
        </div>
      </div>

      <div
        className="modal fade ct_assets_modal"
        id="ct_logout_modal"
        tabIndex="-1"
        aria-labelledby="ct_logout_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-header border-0 pt-0">
              <button
                type="button"
                className="btn-close ct_cloose_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                <i className="fa-solid fa-xmark"></i>
              </button>
            </div>
            <div className="modal-body border-0">
              <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M10.0117 0C4.51172 0 0.0117188 4.5 0.0117188 10C0.0117188 15.5 4.51172 20 10.0117 20C15.5117 20 20.0117 15.5 20.0117 10C20.0117 4.5 15.5117 0 10.0117 0ZM10.0117 15.9C6.81172 15.9 4.11172 13.3 4.11172 10C4.11172 8.3 4.91172 6.6 6.21172 5.5C6.61172 5.2 7.31172 5.2 7.61172 5.6C8.01172 6.1 7.91172 6.7 7.51172 7.1C6.61172 7.8 6.11172 8.9 6.11172 10.1C6.11172 12.2 7.81172 14 10.0117 14C12.2117 14 13.9117 12.3 13.9117 10.1C13.9117 9 13.4117 7.9 12.5117 7.1C12.1117 6.8 12.0117 6.1 12.4117 5.7C12.8117 5.3 13.4117 5.2 13.8117 5.6C15.1117 6.7 15.9117 8.4 15.9117 10.1C15.9117 13.3 13.2117 15.9 10.0117 15.9ZM10.0117 3.5C10.5117 3.5 11.0117 3.9 11.0117 4.5V8.2C11.0117 8.7 10.5117 9.2 10.0117 9.2C9.51172 9.2 9.01172 8.7 9.01172 8.2V4.5C9.01172 3.9 9.51172 3.5 10.0117 3.5Z"
                  fill="#DC0202"
                ></path>
              </svg>
              <h4 className="text-center mb-4 ct_fw_600">Log Out Account</h4>
              <p className="text-center ct_grey_text">
                Are you sure you want to logout? Once you logout, <br /> you
                will need to log in again.
              </p>
              <div className="modal-footer border-0 justify-content-center">
                <button
                  type="button"
                  className="ct_green_btn ct_green_outline_btn"
                  data-bs-dismiss="modal"
                >
                  Cancel
                </button>
                <a
                  data-bs-dismiss="modal"
                  type="button"
                  className="bg-danger ct_green_btn ct_btn_red justify-content-center"
                  style={{ borderColor: "rgb(220, 53, 69)" }}
                  onClick={() => clearAuth()}
                >
                  Logout
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Headers;
