import React from "react";

const ErrorMessage = ({ errors, touched, fieldName,isError=false }) => {
    return (
        <span style={{ color: "red" }}>
            {!isError ?errors[fieldName] && touched[fieldName] && errors[fieldName] : errors[fieldName] ?? ""}
        </span>
    );
};

export default ErrorMessage;