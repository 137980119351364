import React from "react";
import Headers from "../components/Headers";
import CarSlider from "../components/CarSlider";
import Input from "../components/form/Input";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../components/form/ErrorMessage";
import { Formik } from "formik";
import { forgotPasswordSchema } from "../utils/schema";
import { authForgotPassword } from "../redux/action/authAction";
import Loader from "../components/other/Loader";

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isLoading } = useSelector((state) => state.authReducer);
  const initialState = {
    email: "",
  };

  const handleForgotPassword = async (values) => {
    const callback = (response) => {
      if (response.success) navigate(pageRoutes?.login);
    };
    dispatch(authForgotPassword({ payload: values, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Headers />
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_login_main_cnt">
                <h2 className="ct_fs_78 text-center ct_ff_orbitron ct_fw_700">
                  Luxury is waiting for you
                </h2>
                <Formik
                  initialValues={initialState}
                  validationSchema={forgotPasswordSchema}
                  onSubmit={(values) => {
                    handleForgotPassword(values);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    <form onSubmit={handleSubmit} className="mt-3">
                      <div className="row justify-content-center">
                        <div className="col-md-4 mb-3">
                          <div className="form-group">
                            <label
                              htmlFor="email"
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Email *
                            </label>
                            <Input
                              placeholder="email"
                              id="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values?.email}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="email"
                            />
                          </div>
                        </div>

                        <div className="col-md-4 mb-3">
                          <label className="mb-2">&nbsp;</label>
                          <a
                            href="javascrip:void(0)"
                            onClick={handleSubmit}
                            className="ct_green_btn w-100 ct_h_40 d-flex align-items-center justify-content-center text-center"
                            style={{ lineHeight: "0px" }}
                          >
                            Get reset link
                          </a>
                        </div>
                      </div>
                      <div className="d-flex align-items-center justify-content-center ct_flex_column_767 gap-3">
                        <p className="ct_fs_18  mb-0 text-center">
                          <span className="ct_text_op_5">
                            Already know password?
                          </span>{" "}
                          <a
                            href="javascrip:void(0)"
                            onClick={() => navigate(pageRoutes?.login)}
                            className="ct_text_dark_green ct_fw_600"
                          >
                            Login
                          </a>
                        </p>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
          <CarSlider />
        </div>
      </section>
    </>
  );
};

export default ForgotPassword;
