import React from "react";
import Headers from "../components/Headers";
import CarSlider from "../components/CarSlider";
import Input from "../components/form/Input";
import { useNavigate } from "react-router-dom";
import { pageRoutes } from "../routes/pageRoutes";

const ResetPassword = () => {
  const navigate = useNavigate()

  return (
    <>
      <Headers />
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_login_main_cnt">
                <h2 className="ct_fs_78 text-center ct_ff_orbitron ct_fw_700">
                  Luxury is waiting for you
                </h2>
                <form className="mt-3">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                          Password
                        </label>
                        <div className="position-relative">
                          <Input
                            type="password"
                            placeholder="password"
                            id="password"
                          />
                          <i className="fa-regular fa-eye ct_show_eye"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <div className="form-group">
                        <label htmlFor="" className="mb-2 ct_fw_500 ct_fs_16">
                          Re-enter Password
                        </label>
                        <div className="position-relative">
                          <Input
                            type="password"
                            placeholder="password"
                            id="password"
                          />
                          <i className="fa-regular fa-eye ct_show_eye"></i>
                        </div>
                      </div>
                    </div>

                    <div className="col-md-4 mb-3">
                      <label className="mb-2">&nbsp;</label>
                      <button type="button" className="ct_green_btn w-100 ct_h_40">
                        Reset Password
                      </button>
                    </div>
                  </div>
                  <div className="d-flex align-items-center justify-content-center ct_flex_column_767 gap-3">
                    <p className="ct_fs_18  mb-0 text-center">
                      <span className="ct_text_op_5">Already know password?</span>{" "}
                      <a href="javascrip:void(0)" onClick={() => navigate(pageRoutes?.login)} className="ct_text_dark_green ct_fw_600">
                        Login
                      </a>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
          <CarSlider />
        </div>
      </section>
    </>
  );
};

export default ResetPassword;
