import { API_REQUEST } from ".";
import { cancelRideAPI, getRideDetailByIdAPI, getTripHistoryAPI } from "../../routes/apiEndPoints";
import { createAsyncThunk } from "@reduxjs/toolkit";

// fetch-trip-list
export const fetchTripList = createAsyncThunk("fetch-trip-list", async () => {
  try {
    const response = await API_REQUEST({
      url: getTripHistoryAPI,
      method: "GET",
    });
    return response;
  } catch (error) {}
});
// fetch-trip-details
export const fetchTripDetail = createAsyncThunk("fetch-trip-details", async (payload) => {
  try {
    const response = await API_REQUEST({
      url: getRideDetailByIdAPI+`/${payload}`,
      method: "GET",
    });
    return response;
  } catch (error) {}
});


// cancel-trip-details
export const cancelRide = createAsyncThunk(
  "cancel-trip-details",
  async (props) => {
    try {
      const { payload, callback } = props ?? {};
      const response = await API_REQUEST({
        url: cancelRideAPI,
        method: "POST",
        data: payload,
      });
      callback(response);
      return response;
    } catch (error) {}
  }
);