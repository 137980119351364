import * as Yup from "yup";

export const squareRegisterSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
  country: Yup.string()
    .required("Please enter country code")
    .trim()
    .max(2, "Country code cannot exceed 2 characters"),
  given_name: Yup.string().required("Please enter full name").trim(),
  locality: Yup.string().required("Please enter locality").trim(),
  address_line_1: Yup.string().required("Please enter address").trim(),
  postal_code: Yup.string()
    .matches(
      /^(0[1-9]|[1-4][0-9]|5[0-2])\d{3}$/,
      "Please enter valid postal code"
    )
    .required("Please enter postal code")
    .trim(),
});

export const signUpSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name").trim(),
  last_name: Yup.string().required("Please enter last name").trim(),
  mobile_number: Yup.string(),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Strong passwords require at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("password"), null], "Your password must match"),
});

export const signInSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
  password: Yup.string()
    .required("Please enter password")
    .min(8, "Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Strong passwords require at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
    ),
});

export const forgotPasswordSchema = Yup.object().shape({
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
});

export const changePasswordSchema = Yup.object().shape({
  currentPassword: Yup.string()
    .required("Please enter your current password")
    .min(8, "Current Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Please enter a valid password"
    ),
  newPassword: Yup.string()
    .required("Please enter new password")
    .min(8, "New Password cannot be less then 8 characters")
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%&'*+-.,:;<=>?^_`{|}~])/,
      "Strong passwords require at least 1 lowercase letter, 1 uppercase letter, 1 number, and 1 special character."
    ),
  confirmPassword: Yup.string()
    .required("Please enter confirm password")
    .oneOf([Yup.ref("newPassword"), null], "Your password must match"),
});

export const updateProfileSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name").trim(),
  last_name: Yup.string().required("Please enter last name").trim(),
  mobile_number: Yup.string(),
});

export const addPaymentSchema = Yup.object().shape({
  card_holder_name: Yup.string()
    .required("Please enter card holder name")
    .trim(),
  card_number: Yup.string()
    .required("Please enter card number")
    .matches(/^\d+$/, "Card number must contain only digits")
    .min(13, "Card number must be at least 13 digits")
    .max(16, "Card number must be no more than 16 digits"),
  card_cvv: Yup.string()
    .required("Please enter CVV number")
    .matches(/^\d+$/, "CVV must contain only digits")
    .min(3, "CVV must be at least 3 digits")
    .max(4, "CVV must be at most 4 digits"),
  card_expiry: Yup.date()
    .min(new Date(), "Expiry date must be a future date")
    .required("Please enter expiry date"),
});

export const continueAsGuestSchema = Yup.object().shape({
  first_name: Yup.string().required("Please enter first name").trim(),
  last_name: Yup.string().required("Please enter last name").trim(),
  mobile_number: Yup.string(),
  email: Yup.string()
    .trim()
    .email("Please enter a valid email")
    .required("Please enter email")
    .matches(
      /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}(\.[a-zA-Z]{2,})?$/,
      "Please enter a valid email"
    ),
});
