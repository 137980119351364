import React, { useState } from "react";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import Input from "../components/form/Input";
import { pageRoutes } from "../routes/pageRoutes";
import { useNavigate } from "react-router";
import { continueAsGuestSchema, signInSchema } from "../utils/schema";
import Loader from "../components/other/Loader";
import { Formik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  authLogin,
  continueAsGuest,
  fetchProfile,
} from "../redux/action/authAction";
import ErrorMessage from "../components/form/ErrorMessage";
import Eye from "../components/Eye";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

const ContinueAsGuest = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isShowError, setShowError] = useState(false);

  const [isEye, setIsEye] = useState(false);
  const { isLoading } = useSelector((state) => state.authReducer);
  const initialState = {
    email: "",
    password: "",
  };

  const initialState1 = {
    first_name: "",
    last_name: "",
    email: "",
    mobile_number: "",
  };

  const handleLogin = async (values) => {
    const callback = (response) => {
      if (response.success) {
        dispatch(fetchProfile());
        localStorage.setItem("typeofLogin", false)
        setTimeout(() => {
          window.location.href = pageRoutes?.select_destination
        }, 1000);
        // navigate(pageRoutes?.select_destination,{state:{typeofLogin : false}});
      }
    };
    dispatch(authLogin({ payload: values, callback }));
  };

  const handleContinueAsGuest = async (values) => {
    const validateData = !isValidPhoneNumber(values?.mobile_number)
    if (validateData == true) {
      setShowError(true);
      // return;
    } else {
      const callback = (response) => {
        if (response.success) {
          localStorage.setItem("typeofLogin", false)
          setTimeout(() => {
            window.location.href = pageRoutes?.select_destination
          }, 1000);
          //  navigate(pageRoutes?.select_destination,{state:{typeofLogin : false}})
        };
      };
      dispatch(continueAsGuest({ payload: values, callback }));
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Headers />
      <section className="mt-4">
        <div className="ct_light_grey_bg">
          <div className="container">
            <div className="row">
              <div
                className="col-md-12"
                data-aos="fade-down"
                data-aos-duration="1000"
              >
                <p className="mb-1 ct_fs_14 ct_fw_500">STEP 3 OF 4</p>
                <div className="d-flex align-items-center gap-2 justify-content-between ct_flex_column_767">
                  <h4 className="mb-0 ct_fs_24 ct_fw_500 mb-3 mb-md-0">
                    Login
                  </h4>
                  <ul className="d-flex align-items-center gap-4 flex-wrap">
                    <li className="d-flex align-items-center gap-2">
                      <div className="ct_circle_24 ct_active_check">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <p className="mb-0 ct_fs_14 ct_fw_500">
                        Ride Information
                      </p>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      <div className="ct_circle_24 ct_active_check">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <p className="mb-0 ct_fs_14 ct_fw_500">Vehicle Type</p>
                    </li>
                    <li className="d-flex align-items-center gap-2">
                      <div className="ct_circle_24 ">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <p className="mb-0 ct_fs_14 ct_fw_500">Login</p>
                    </li>

                    <li className="d-flex align-items-center gap-2">
                      <div className="ct_circle_24 ">
                        <p className="mb-0 text-white">4</p>
                      </div>
                      <p className="mb-0 ct_fs_14 ct_fw_500 ct_text_op_5">
                        Payment
                      </p>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div className="row  pb-5">
                    <div
                      className="col-lg-8 mb-4 mb-lg-0"
                      data-aos="fade-right"
                      data-aos-duration="1000"
                    >
                      <div className="ct_profile_form">
                        <h4 className="ct_fs_24 ct_fw_600 mb-4">
                          Continue as Guest
                        </h4>

                        <Formik
                          initialValues={initialState1}
                          validationSchema={continueAsGuestSchema}
                          onSubmit={(values, actions) => {
                            handleContinueAsGuest(values, actions);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                            setFieldValue,
                          }) => (
                            <form className="mt-3" onSubmit={handleSubmit}>
                              <div className="row">
                                {/* First Name */}
                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="firstname"
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      First Name<span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="first name"
                                        id="first_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.first_name}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="first_name"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Last Name */}
                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="lastname"
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Last Name<span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="last name"
                                        id="last_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.last_name}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="last_name"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Email */}
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Email <span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="email"
                                        id="email"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.email}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="email"
                                      />
                                    </div>
                                  </div>
                                </div>
                                {/* Phone */}
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor="phonenumber"
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Phone<span>*</span>
                                    </label>
                                    <div className="">
                                      <PhoneInput
                                        international
                                        defaultCountry="CO"
                                        className="form-control "
                                        placeholder="Please enter phone number +57 xxxx xxxx xx"
                                        onChange={(value) => {
                                          setFieldValue(
                                            "mobile_number",
                                            value ?? ""
                                          );
                                          if (
                                            !isPossiblePhoneNumber(
                                              values?.mobile_number
                                            ) ||
                                            !isValidPhoneNumber(
                                              values?.mobile_number
                                            )
                                          )
                                            setShowError(true);
                                          else setShowError(false);
                                        }}
                                        value={values?.mobile_number}
                                        error={
                                          values?.mobile_number
                                            ? isValidPhoneNumber(
                                              values?.mobile_number
                                            )
                                              ? null
                                              : "Invalid phone number"
                                            : "Phone number required"
                                        }
                                      />
                                    </div>
                                    {isShowError && (
                                      <span style={{ color: "red" }}>
                                        {
                                          !isValidPhoneNumber(
                                            values?.mobile_number
                                          ) &&
                                          "Please enter a valid phone number"}
                                      </span>
                                    )}
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="button"
                                  onClick={handleSubmit}
                                  className="ct_green_btn w-100 ct_out_line_btn ct_light_green_btn ct_fw_500 ct_fs_14"
                                >
                                  CONTINUE AS GUEST
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                    <div
                      className="col-lg-4 mb-4 mb-lg-0 "
                      data-aos="fade-left"
                      data-aos-duration="1000"
                    >
                      <div className="ct_border_left_1 h-100">
                        <div className="ct_right_login_small   ">
                          <div className="ct_profile_form pb-5">
                            <h4 className="ct_fs_24 ct_fw_600 mb-4">
                              Login To Continue
                            </h4>
                            <Formik
                              initialValues={initialState}
                              validationSchema={signInSchema}
                              onSubmit={(values) => {
                                handleLogin(values);
                              }}
                            >
                              {({
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                              }) => (
                                <form onSubmit={handleSubmit}>
                                  <div className="row">
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="email1"
                                          className="mb-2 ct_fw_500 ct_fs_16"
                                        >
                                          Email <span>*</span>
                                        </label>
                                        <div className="position-relative">
                                          <Input
                                            placeholder="email"
                                            id="email"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.email}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            touched={touched}
                                            fieldName="email"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                      <div className="form-group">
                                        <label
                                          htmlFor="Password"
                                          className="mb-2 ct_fw_500 ct_fs_16"
                                        >
                                          Password <span>*</span>
                                        </label>
                                        <div className="position-relative">
                                          <div className="position-relative">
                                            <Input
                                              placeholder="password"
                                              id="password"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.password}
                                              type={isEye ? "text" : "password"}
                                            />
                                            <Eye
                                              isEye={isEye}
                                              onClick={setIsEye}
                                            />
                                          </div>
                                          <ErrorMessage
                                            errors={errors}
                                            touched={touched}
                                            fieldName="password"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="mt-3">
                                    <button
                                      type="button"
                                      onClick={handleSubmit}
                                      className="ct_green_btn w-100  ct_fw_500 ct_fs_14"
                                    >
                                      CONTINUE
                                    </button>
                                  </div>
                                </form>
                              )}
                            </Formik>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default ContinueAsGuest;
