import React, { useEffect } from "react";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import SubHeadings from "../components/SubHeadings";
import { pipGetProfile } from "../utils/pip";
import PhoneInput, {
  isPossiblePhoneNumber,
  isValidPhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchProfile } from "../redux/action/authAction";
import Loader from "../components/other/Loader";

const MyProfile = () => {
  const dispatch = useDispatch();

  const { first_name, last_name, mobile_number } = pipGetProfile();
  const { isLoading } = useSelector((state) => state.authReducer);

  useEffect(() => {
    dispatch(fetchProfile());
  }, []);

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div
                      className="col-md-6 mx-auto"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <div className="ct_profile_form pb-5">
                        <h4 className="ct_fs_24 ct_fw_600 mb-4">Profile</h4>
                        <form>
                          <div className="row">
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="mb-2 ct_fw_500 ct_fs_16"
                                >
                                  First Name <span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control ct_custom_input"
                                  placeholder="Enter First Name"
                                  value={first_name ?? ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="mb-2 ct_fw_500 ct_fs_16"
                                >
                                  Last Name<span>*</span>
                                </label>
                                <input
                                  type="text"
                                  className="form-control ct_custom_input"
                                  placeholder="Enter Last Name"
                                  value={last_name ?? ""}
                                  disabled
                                />
                              </div>
                            </div>
                            <div className="col-md-12 mb-3">
                              <div className="form-group">
                                <label
                                  htmlFor=""
                                  className="mb-2 ct_fw_500 ct_fs_16"
                                >
                                  Phone<span>*</span>
                                </label>
                                <div className="position-relative">
                                <PhoneInput
                                 international
                                  className="form-control " 
                                  placeholder="Please enter phone number"
                                 
                                  value={mobile_number}
                                  error={
                                    mobile_number
                                      ? isValidPhoneNumber(mobile_number)
                                        ? null
                                        : "Invalid phone number"
                                      : "Phone number required"
                                  }
                                  
                                />
                                <div className="ct_disable_input"></div>
                                </div>

                                {/* <PhoneInput
                                        className="form-control "
                                        placeholder="Please enter phone number"
                                        value={mobile_number}
                                        disabled
                                      /> */}
                              </div>
                            </div>
                          </div>
                          {/* <div className="mt-3">
                            <button
                              type="button"
                              className="ct_green_btn w-100"
                            >
                              Save
                            </button>
                          </div> */}
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MyProfile;
