import React from "react";

const Input = ({
  isPlaceHolder = false,
  type = "text",
  className = "",
  isClassName = false,
  placeholder = "",
  value,
  onChange,
  ...props
}) => {
  return (
    <input
      type={type}
      className={isClassName ? className : `form-control ct_custom_input ${className}`}
      placeholder={isPlaceHolder==  true ? placeholder: `Please enter ${placeholder}`}
      value={value}
      onChange={onChange}
      {...props}
    />
  );
};

export default Input;
