import React, { useEffect } from "react";
import { useNavigate, useLocation } from "react-router";
import Headers from "../components/Headers";
import SubHeadings from "../components/SubHeadings";
import { useDispatch, useSelector } from "react-redux";
import { cancelRide, fetchTripDetail } from "../redux/action/tripAction";
import Loader from "../components/other/Loader";
import { curSym, pipGetProfile, pipRideTimeView } from "../utils/pip";
import { pageRoutes } from "../routes/pageRoutes";
import { message } from "antd";

const TripDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const tripId = useLocation()?.state?.id ?? null;
  const { tripDetail, isLoading } = useSelector((state) => state.tripReducer);

  useEffect(() => {
    dispatch(fetchTripDetail(tripId));
  }, []);

  const checkEditAvailability = (callback, msg) => {
    const pickupTime = new Date(tripDetail?.pickup_date_time).getTime();
    const currentTime = Date.now();
    const timeDifference = pickupTime - currentTime;

    // Check if time difference is less than 12 hours (in milliseconds)
    if (timeDifference <= 12 * 60 * 60 * 1000) {
      message.info(msg);
    } else {
      callback();
    }
  };

  const handleCancelRide = () => {
    const data = {
      user_id: pipGetProfile()?.id,
      booking_id: tripDetail?.id,
    };
    const callback = (response) => {
      if (response?.success) {
        dispatch(fetchTripDetail(tripId));
      }
    };
    dispatch(cancelRide({ payload: data, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div
                      className="col-md-12 mx-auto"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <div className="ct_profile_form pb-5 d-flex align-items-center flex-wrap gap-3">
                        <a
                          href="javascript:void(0)"
                          onClick={() => navigate(-1)}
                          className="ct_fs_18 ct_fw_500  text-dark"
                        >
                          <i className="fa-solid fa-arrow-left me-2"></i> Back
                        </a>
                        <div className="">
                          <a
                            href="javascript:void(0)"
                            className="ct_fs_24 ct_fw_500 mb-3 text-dark"
                          >
                            Cost : {curSym}
                            {tripDetail?.total_cost}
                          </a>
                        </div>
                        <div className="d-flex align-items-center gap-3 ms-auto ct_ms_start_502">
                        <div>
                             
                             <strong>Ride Status : </strong><span className="ct_status_bg_345">{ tripDetail?.booking_status}</span>
                          
                         </div>
                          
                          {tripDetail?.booking_status ==
                            "Pending to assign" && (
                            <>
                              <div
                                onClick={() =>
                                  checkEditAvailability(() => {
                                    navigate(pageRoutes.dashboard, {
                                      state: { isEdit: true },
                                    });
                                  }, "Editing is no longer available within 12 hours of the trip.")
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="ct_green_btn py-2 ct_fs_16 ct_btn_w_125 d-block text-center"
                                >
                                  Edit
                                </a>
                              </div>
                              <div
                                onClick={() =>
                                  checkEditAvailability(() => {
                                    handleCancelRide();
                                  }, "Cancellation is no longer available within 12 hours of the trip.")
                                }
                              >
                                <a
                                  href="javascript:void(0)"
                                  className="ct_green_btn ct_btn_red py-2 ct_fs_16 ct_btn_w_125 d-block text-center"
                                >
                                  Cancel
                                </a>
                              </div>
                            </>
                          )}
                          
                            
                       
                        </div>
                        {/*Not Return Pickut Details */}

                        <div className="row mt-5">
                          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                            <div className="trip_details_cnt">
                              <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                Pickup Location
                              </span>
                              <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                {tripDetail?.pick_up_location}
                              </h5>
                            </div>
                          </div>
                          {tripDetail?.service_type == "hourly" ? (
                            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                              <div className="trip_details_cnt ct_border_left_1">
                                <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                  Duration (Hr/Min)
                                </span>
                                <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                  {tripDetail?.hourly_duration
                                    ? tripDetail?.hourly_duration
                                    : "N/A"}
                                </h5>
                              </div>
                            </div>
                          ) : (
                            <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                              <div className="trip_details_cnt ct_border_left_1">
                                <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                  Destination
                                </span>
                                <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                  {tripDetail?.designation}
                                </h5>
                              </div>
                            </div>
                          )}
                          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                            <div className="trip_details_cnt ct_border_left_1">
                              <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                Date & Time
                              </span>
                              <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                {pipRideTimeView(tripDetail?.pickup_date_time)}
                              </h5>
                            </div>
                          </div>

                          <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                            <div className="trip_details_cnt ct_border_left_1">
                              <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                Car Name
                              </span>
                              <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                {tripDetail?.car?.car_type}
                              </h5>
                            </div>
                          </div>
                        </div>

                        {tripDetail?.is_return && (
                          <>
                            <button className="ct_grey_btn my-4">
                              <svg
                                width="19"
                                height="24"
                                viewBox="0 0 19 24"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M14.6429 18.68V9.33333H12.0714V18.68H8.21429L13.3571 24L18.5 18.68H14.6429ZM5.64286 0L0.5 5.32H4.35714V14.6667H6.92857V5.32H10.7857L5.64286 0Z"
                                  fill="#004225"
                                  fillOpacity="0.54"
                                />
                              </svg>{" "}
                              Return trip
                            </button>
                            <div className="row">
                              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                <div className="trip_details_cnt">
                                  <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                    Pickup Location
                                  </span>
                                  <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                    {tripDetail?.designation}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                <div className="trip_details_cnt ct_border_left_1">
                                  <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                    Destination
                                  </span>
                                  <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                    {tripDetail?.pick_up_location}
                                  </h5>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                <div className="trip_details_cnt ct_border_left_1">
                                  <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                    Date & Time
                                  </span>
                                  <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                    {pipRideTimeView(
                                      tripDetail?.return_pickup_date_time
                                    )}
                                  </h5>
                                </div>
                              </div>

                              <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                                <div className="trip_details_cnt ct_border_left_1">
                                  <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                                    Car Name
                                  </span>
                                  <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                                    {tripDetail?.car?.car_type}
                                  </h5>
                                </div>
                              </div>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div
        className="modal fade"
        id="ct_rate_modal"
        tabindex="-1"
        aria-labelledby="ct_rate_modalLabel"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content pb-4">
            <div className="modal-header border-0 pb-0">
              <button
                type="button"
                className="btn-close ct_btn_close"
                data-bs-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div className="modal-body border-0 pt-0 ">
              <div className="ct_rate_modal_main">
                <h4 className="ct_fs_24 text-center mb-2">Rate us</h4>
                <ul className="ct_review_rating">
                  <li>
                    <i className="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <i className="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <i className="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <i className="fa-solid fa-star"></i>
                  </li>
                  <li>
                    <i className="fa-solid fa-star"></i>
                  </li>
                </ul>
                <div className="form-group mt-4">
                  <label htmlFor="" className="mb-2 ct_fw_500">
                    Write your review
                  </label>
                  <input
                    type="text"
                    className="form-control ct_custom_input"
                    placeholder="Write your review"
                  />
                </div>
              </div>
            </div>
            <div className="modal-footer border-0 justify-content-end">
              <button
                type="button"
                className="ct_green_btn ct_out_line_btn py-2 ct_btn_w_125 ct_fs_16"
                data-bs-dismiss="modal"
              >
                Cancel
              </button>
              <button
                type="button"
                className="ct_green_btn py-2 ct_btn_w_125 ct_fs_16"
                data-bs-dismiss="modal"
              >
                Rate
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TripDetails;
