import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import NavHeading from "../components/NavHeading";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { fetchCarList, selectCar } from "../redux/action/bookingAction";
import Loader from "../components/other/Loader";
import NoRecord from "../components/other/NoRecord";
import {
  curSym,
  getBookingData,
  pipGetAccessToken,
  pipRideTimeView,
  setBookingData,
} from "../utils/pip";
import { fetchTripDetail } from "../redux/action/tripAction";
import { current } from "@reduxjs/toolkit";

const Standard = () => {
  const tripDetail = getBookingData();
  const { id, service_type, additional_cost, is_booked } = getBookingData();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("Standard");
  const { Loading, carList } = useSelector((state) => state?.bookingReducer);
  const [filterList, setFilterList] = useState(carList);
  useEffect(() => {
    dispatch(
      fetchCarList({
        booking_id: id ?? "",
      })
    );

    dispatch(fetchTripDetail(id ?? ""));
  }, []);

  useEffect(() => {
    let filtered = [...carList];

    if (activeTab) {
      filtered = filtered?.filter((sub) => {
        return sub?.car_category?.toLowerCase() === activeTab?.toLowerCase();
      });
    }
    setFilterList(filtered);
  }, [activeTab, carList]);

  const handleSelect = (car_id) => {
    const data = {
      car_id,
      booking_id: id,
    };
    const callback = (response) => {
      if (response?.success) {
        setBookingData(response?.data);
        if (
          (response?.data?.additional_cost === "0.00" ||
            response?.data?.additional_cost == 0) &&
          is_booked
        ) {
          return navigate(pageRoutes.my_trip_history);
        }
        if (!pipGetAccessToken()) {
          navigate(pageRoutes.continueAsGuest);
        } else {
          localStorage.setItem("typeofLogin", true);
          setTimeout(() => {
            window.location.href = pageRoutes?.select_destination;
          }, 800);
        
        }
      }
    };
    dispatch(selectCar({ payload: data, callback }));
  };
  if (Loading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <NavHeading path={activeTab} onClick={setActiveTab} />
        <div className="ct_py_100">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 mx-auto">
                {filterList?.length > 0 ? (
                  filterList?.map((item, index) => (
                    <div className="ct_mt_40">
                      <div className={"row"}>
                        <div
                          className="col-md-6 mb-4 mb-md-0"
                          data-aos="fade-right"
                          data-aos-duration="1000"
                        >
                          <div className="ct_card_left_detail">
                            <figure>
                              <div className="ct_card_img12">
                                <img src={item?.car_image} alt="" />
                              </div>
                              <figcaption>
                                <h4 className="ct_fs_40 ct_text_dark_green ct_fw_700">
                                  {`${curSym} ${(item?.total_cost_show_only).toFixed(
                                    2
                                  )}`}
                                  {/* {service_type == "point-to-point"
                                    ? `${curSym} ${item?.per_km_price}/km`
                                    : `${curSym} ${item?.hourly_price} hourly`} */}
                                </h4>
                                <div className="d-flex  gap-2 mt-4">
                                  <img
                                    src="../assets/img/secure_icon.svg"
                                    alt=""
                                    className="ct_secure_icon"
                                  />
                                  <div>
                                    <h5 className="ct_fs_16 ct_fw_500">
                                      Price includes taxes, tolls & tip.
                                    </h5>
                                    <p className="mb-0 ct_fw_500 ct_fs_16 ct_text_op_5">
                                      No hidden costs.
                                    </p>
                                  </div>
                                </div>
                                <div className="ct_dark_grey_bg">
                                  <ul>
                                    <li>
                                      <img
                                        src="../assets/img/multiple_users.svg"
                                        alt=""
                                        className="ct_icon_24"
                                      />
                                      <div>
                                        <p className="mb-0 ct_fs_16 ct_fw_500">
                                          Max :{" "}
                                          <span>{item?.persons_limit}</span>
                                        </p>
                                      </div>
                                    </li>
                                    <li>
                                      <img
                                        src={"../assets/img/beg.svg"}
                                        alt=""
                                        className="ct_icon_24"
                                      />
                                      <div>
                                        <p className="mb-0 ct_fs_16 ct_fw_500">
                                          Max : <span>{item?.bags_limit}</span>
                                        </p>
                                      </div>
                                    </li>
                                  </ul>
                                </div>
                              </figcaption>
                            </figure>
                          </div>
                        </div>
                        <div
                          className="col-md-6 mb-4 mb-md-0"
                          data-aos="fade-left"
                          data-aos-duration="1000"
                        >
                          <div className="ct_card_right_detail">
                            <div className="ct_dark_grey_bg w-auto">
                              <h4 className="ct_fs_24 ct_fw_600 mb-1">
                                {item?.car_type}
                              </h4>
                              <p>{item?.description}</p>
                              <a
                                href="javascript:void(0)"
                                onClick={() => handleSelect(item?.id)}
                                className="ct_green_btn w-100 d-block text-center"
                              >
                                SELECT
                              </a>
                            </div>
                            <ul className="ct_features_detail mt-5">
                              <li>
                                <img
                                  src="../assets/img/clock_icon1.svg"
                                  alt=""
                                />
                                <p className="mb-0 ct_fs_16 ct_fw_500 ct_text_op_5">
                                  Free 90 min wait time for airport pickups (60
                                  min for domestic flights) and 15 min for all
                                  others
                                </p>
                              </li>
                              <li className="ct_mt_17">
                                <img src="../assets/img/wifi_icon.svg" alt="" />
                                <p className="mb-0 ct_fs_16 ct_fw_500 ct_text_op_5">
                                  Complimentary in-vehicle Wi-Fi available
                                </p>
                              </li>
                              <li className="ct_mt_17">
                                <img
                                  src="../assets/img/water_drop_icon.svg"
                                  alt=""
                                />
                                <p className="mb-0 ct_fs_16 ct_fw_500 ct_text_op_5">
                                  Complimentary bottled water
                                </p>
                              </li>
                              <li className="ct_mt_17">
                                <img
                                  src="../assets/img/crose_icon.svg"
                                  alt=""
                                />
                                <p className="mb-0 ct_fs_16 ct_fw_500 ct_text_op_5">
                                  Free Cancellation 24 hours before pickup
                                </p>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <NoRecord />
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Standard;
