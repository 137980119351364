import { Formik } from "formik";
import "react-phone-number-input/style.css";
import Headers from "../../components/Headers";
import Input from "../../components/form/Input";
import { useNavigate } from "react-router-dom";
import Loader from "../../components/other/Loader";
import { pageRoutes } from "../../routes/pageRoutes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { squareRegisterSchema } from "../../utils/schema";
import { authRegister, squareRegister } from "../../redux/action/authAction";
import ErrorMessage from "../../components/form/ErrorMessage";
import { pipGetProfile } from "../../utils/pip";

const SquareRegister = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { email } = pipGetProfile() ?? {};
  const { isLoading } = useSelector((state) => state.authReducer);

  const initialState = {
    email: email ?? "",
    country: "",
    locality: "",
    postal_code: "",
    given_name: "",
  };

  const handleSquareRegister = async (values) => {
    const callback = (response) => {
      if (response.success) {
        // navigate(pageRoutes.login);
      }
    };
    const payload = {
      address: {
        address_line_1: values?.address_line_1,
        locality: values?.locality,
        country: values?.country,
        postal_code: values?.postal_code,
      },
      email_address: values?.email,
      given_name: values?.given_name,
      user_id: pipGetProfile()?.id ?? null,
    };

    dispatch(squareRegister({ payload, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <>
      <Headers />
      <section className="mt-5">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_login_main_cnt">
                <h2 className="ct_fs_78 text-center ct_ff_orbitron ct_fw_700">
                  Square Registration
                </h2>
                <Formik
                  initialValues={initialState}
                  validationSchema={squareRegisterSchema}
                  onSubmit={(values, actions) => {
                    handleSquareRegister(values, actions);
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    setFieldValue,
                  }) => (
                    <form className="mt-3" onSubmit={handleSubmit}>
                      <div className="row">
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Full Name*
                            </label>
                            <Input
                              placeholder="first name"
                              id="given_name"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.given_name}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="given_name"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Email*
                            </label>
                            <Input
                              placeholder="email"
                              id="email"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.email}
                              disabled={true}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="email"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Country Code *
                            </label>
                            <Input
                              placeholder="country code"
                              id="country"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.country}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="country"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Locality *
                            </label>
                            <Input
                              placeholder="locality"
                              id="locality"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.locality}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="locality"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Postal Code *
                            </label>
                            <Input
                              placeholder="postal code"
                              id="postal_code"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.postal_code}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="postal_code"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4">
                          <div className="form-group">
                            <label
                              htmlFor=""
                              className="mb-2 ct_fw_500 ct_fs_16"
                            >
                              Address *
                            </label>
                            <Input
                              placeholder="address"
                              id="address_line_1"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.address_line_1}
                            />
                            <ErrorMessage
                              errors={errors}
                              touched={touched}
                              fieldName="address_line_1"
                            />
                          </div>
                        </div>
                        <div className="col-md-4 mb-4 mx-auto">
                          <label className="mb-2">&nbsp;</label>
                          <button
                            type="button"
                            className="ct_green_btn w-100 ct_h_40"
                            onClick={handleSubmit}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default SquareRegister;
