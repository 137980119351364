import React, { useState } from "react";
import Footer from "../components/Footer";
import { useDispatch, useSelector } from "react-redux";
import Headers from "../components/Headers";
import { useNavigate } from "react-router-dom";
import SubHeadings from "../components/SubHeadings";
import { Formik } from "formik";
import { pageRoutes } from "../routes/pageRoutes";
import Loader from "../components/other/Loader";
import { changePasswordSchema } from "../utils/schema";
import Input from "../components/form/Input";
import Eye from "../components/Eye";
import ErrorMessage from "../components/form/ErrorMessage";
import { changePassword } from "../redux/action/authAction";

const ChangePassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isToggle, isLoading } = useSelector((state) => state.authReducer);
  const [eyes, setEyes] = useState({
    eye1: false,
    eye2: false,
    eye3: false,
  });
  const initialState = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
  };
  const handleChangePassword = async (values) => {
    const callback = (response) => {
      if (response.success) {
        navigate(pageRoutes?.my_profile);
      }
    };
    const { confirmPassword, ...rest } = values;
    dispatch(changePassword({ payload: rest, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div
                    className="row"
                    data-aos="fade-down"
                    data-aos-duration="1000"
                  >
                    <div className="col-md-6 mx-auto">
                      <div className="ct_profile_form pb-5">
                        <h4 className="ct_fs_24 ct_fw_600 mb-4">
                          Update Password
                        </h4>
                        <Formik
                          initialValues={initialState}
                          validationSchema={changePasswordSchema}
                          onSubmit={(values, actions) => {
                            handleChangePassword(values, actions);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Current Password <span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <div className="position-relative">
                                        <Input
                                          placeholder="current password"
                                          id="currentPassword"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.currentPassword}
                                          type={eyes.eye1 ? "text" : "password"}
                                        />
                                        <Eye
                                          isEye={eyes.eye1}
                                          onClick={(val) =>
                                            setEyes({
                                              ...eyes,
                                              eye1: val,
                                            })
                                          }
                                        />
                                      </div>
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="currentPassword"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      New Password<span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <div className="position-relative">
                                        <Input
                                          placeholder="new password"
                                          id="newPassword"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.newPassword}
                                          type={eyes.eye2 ? "text" : "password"}
                                        />
                                        <Eye
                                          isEye={eyes.eye2}
                                          onClick={(val) =>
                                            setEyes({
                                              ...eyes,
                                              eye2: val,
                                            })
                                          }
                                        />
                                      </div>
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="newPassword"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Confirm Password<span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <div className="position-relative">
                                        <Input
                                          placeholder="confirm password"
                                          id="confirmPassword"
                                          onChange={handleChange}
                                          onBlur={handleBlur}
                                          value={values.confirmPassword}
                                          type={eyes.eye3 ? "text" : "password"}
                                        />
                                        <Eye
                                          isEye={eyes.eye3}
                                          onClick={(val) =>
                                            setEyes({
                                              ...eyes,
                                              eye3: val,
                                            })
                                          }
                                        />
                                      </div>
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="confirmPassword"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3">
                                <button
                                  type="button"
                                  className="ct_green_btn w-100"
                                  onClick={handleSubmit}
                                >
                                  Update
                                </button>
                              </div>
                            </form>
                          )}
                        </Formik>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ChangePassword;
