import React from "react";
import { useNavigate, useLocation } from "react-router";
import { pageRoutes } from "../routes/pageRoutes";

const SubHeadings = () => {
  const pathname = useLocation().pathname;
  const navigate = useNavigate();

  return (
    <div className="ct_light_grey_bg">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <ul className="nav nav-pills ct_profile_tab">
            <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.dashboard)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link text-center`}
                  type="button"
                >
                  Dashboard
                </a>
              </li>
              <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.my_profile)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.my_profile && "active"
                  } text-center`}
                  type="button"
                >
                  My Profile
                </a>
              </li>
              <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.updateProfile)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.updateProfile && "active"
                  } text-center`}
                  type="button"
                >
                  Edit Profile
                </a>
              </li>
              <li
                className="nav-item"
                onClick={() => navigate(pageRoutes.change_password)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.change_password && "active"
                  } text-center`}
                  type="button"
                >
                  Change Password
                </a>
              </li>
              {/* <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.payment_method)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.payment_method && "active"
                  } text-center`}
                  type="button"
                >
                  Payment Methods
                </a>
              </li> */}
              <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.my_trip_history)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.my_trip_history && "active"
                  } text-center`}
                  type="button"
                >
                  My Trip History
                </a>
              </li>
              <li
                className="nav-item"
                role="presentation"
                onClick={() => navigate(pageRoutes.notification)}
              >
                <a
                  href="javascript:void(0)"
                  className={`nav-link ${
                    pathname == pageRoutes?.notification && "active"
                  } text-center`}
                  type="button"
                >
                  Notifications
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SubHeadings;
