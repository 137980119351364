import ChangePassword from "../pages/ChangePassword";
import Dashboard from "../pages/Dashboard";
import MyProfile from "../pages/MyProfile";
import MyTripHistory from "../pages/MyTripHistory";
import PaymentMehtod from "../pages/PaymentMehtod";
import Notification from "../pages/Notifications";
import AddPaymentMethod from "../pages/AddPaymentMethod";
import EditPaymentMethod from "../pages/EditPaymentMethod";
import TripDetails from "../pages/TripDetails";
import Standard from "../pages/Standard";
import ForgotPassword from "../pages/ForgotPassword";
import ResetPassword from "../pages/ResetPassword";
import SignUp from "../pages/SignUp";
import Login from "../pages/Login";
import ContinueAsGuest from "../pages/ContinueAsGuest";
import SelectDestination from "../pages/SelectDestination";
import UpdateProfile from "../pages/UpdateProfile";
import GoogleMapCom from "../pages/GoogleMap";
import SquareRegister from "../pages/square/Register";

export const pageRoutes = {
  dashboard: "/",
  my_profile: "/profile",
  updateProfile: "/update-profile",
  change_password: "/change-password",
  payment_method: "/payment-method",
  my_trip_history: "/my-trip-history",
  trip_details: "/trip-details",
  notification: "/notification",
  add_payment_method: "/add-payment-method",
  edit_payment_method: "/edit-payment-method",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  signup: "/signup",
  login: "/login",
  statndard: "/standard",
  continueAsGuest: "/continue-as-guest",
  select_destination: "/select-destination",
  squareRegister: "/square-register",
};

export const AllRoutes = [
  {
    name: "Dashboard",
    path: pageRoutes.dashboard,
    element: <Dashboard />,
    isPrivate: false,
  },
  {
    name: "MyProfile",
    path: pageRoutes.my_profile,
    element: <MyProfile />,
    isPrivate: false,
  },
  {
    name: "UpdateProfile",
    path: pageRoutes.updateProfile,
    element: <UpdateProfile />,
    isPrivate: false,
  },
  {
    name: "ChangePassword",
    path: pageRoutes.change_password,
    element: <ChangePassword />,
    isPrivate: false,
  },
  {
    name: "PaymentMethod",
    path: pageRoutes.payment_method,
    element: <PaymentMehtod />,
    isPrivate: false,
  },
  {
    name: "MyTripHistory",
    path: pageRoutes.my_trip_history,
    element: <MyTripHistory />,
    isPrivate: false,
  },
  {
    name: "TripDetails",
    path: pageRoutes.trip_details,
    element: <TripDetails />,
    isPrivate: false,
  },
  {
    name: "Notification",
    path: pageRoutes.notification,
    element: <Notification />,
    isPrivate: false,
  },
  {
    name: "AddPayemntMethod",
    path: pageRoutes.add_payment_method,
    element: <AddPaymentMethod />,
    isPrivate: false,
  },
  {
    name: "EditPayemntMethod",
    path: pageRoutes.edit_payment_method,
    element: <EditPaymentMethod />,
    isPrivate: false,
  },
  {
    name: "ForgotPassword",
    path: pageRoutes.forgotPassword,
    element: <ForgotPassword />,
    isPrivate: false,
  },
  {
    name: "ResetPassword",
    path: pageRoutes.resetPassword,
    element: <ResetPassword />,
    isPrivate: false,
  },
  {
    name: "SignUp",
    path: pageRoutes.signup,
    element: <SignUp />,
    isPrivate: false,
  },
  {
    name: "Login",
    path: pageRoutes.login,
    element: <Login />,
    isPrivate: false,
  },
  {
    name: "Statndard",
    path: pageRoutes.statndard,
    element: <Standard />,
    isPrivate: false,
  },
  {
    name: "continueAsGuest",
    path: pageRoutes.continueAsGuest,
    element: <ContinueAsGuest />,
    isPrivate: false,
  },
  {
    name: "SelectDestination",
    path: pageRoutes.select_destination,
    element: <SelectDestination />,
    isPrivate: false,
  },
  {
    name: "GoogleMapCom",
    path: "/map",
    element: <GoogleMapCom />,
    isPrivate: false,
  },
  {
    name: "SquareRegister",
    path: pageRoutes.squareRegister,
    element: <SquareRegister />,
    isPrivate: false,
  },
];
