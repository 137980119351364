import React from 'react'
import { pageRoutes } from '../routes/pageRoutes';

const Footer = () => {
    return (
        <footer>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="ct_footer_information">
                            <div className="ct_footer_logo">
                                <img src="../assets/img/Text_1.svg" alt="" />
                            </div>
                            <ul className="ct_address_dtl ct_mt_32">
                                <li>
                                    <i className="bi bi-geo-alt"></i>
                                    <div>
                                        <p className="mb-0">25566 Hc 1, Glenallen, Alaska, 99588, USA</p>
                                    </div>
                                </li>
                                <li>
                                    <i className="bi bi-telephone"></i>
                                    <div>
                                        <a href="tel:+011478427312" className="mb-0">+01 1 4784 273 12</a>
                                    </div>
                                </li>
                                <li>
                                    <i className="bi bi-envelope"></i>
                                    <div>
                                        <a href="mailto:car@domain.com" className="mb-0">car@domain.com</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="ct_footer_menu">
                            <h4 className="ct_fs_16 ct_fw_500 text-white">Follow Us</h4>
                            <ul className="ct_footer_social_media ct_mt_32">
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="../assets/img/facebook.png" alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="../assets/img/instagram.png" alt="" />
                                    </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        <img src="../assets/img/youtube.png" alt="" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-4 mb-4 mb-lg-0">
                        <div className="ct_footer_menu">
                            <h4 className="ct_fs_16 ct_fw_500 text-white">Our Product</h4>
                            <ul className="ct_footer_menu_links ct_mt_32">
                                <li>
                                    <a href="javascript:void(0)" >
                                        About Us
                                </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        Privacy Policy
                                </a>
                                </li>
                                <li>
                                    <a href="javascript:void(0)">
                                        Term & Conditions
                                </a>
                                </li>
                            </ul>
                        </div>
                    </div>

                </div>
                <div className="row ct_sub_footer">
                    <div className="col-md-12">
                        <p className="mb-0">Copyright 2024 ・ Evo Go, All Rights Reserved</p>
                    </div>

                </div>

            </div>
        </footer>
    )
}

export default Footer;