import React from "react";
import { Formik } from "formik";
import Headers from "../components/Headers";
import Input from "../components/form/Input";
import Loader from "../components/other/Loader";
import { pageRoutes } from "../routes/pageRoutes";
import { addPaymentSchema } from "../utils/schema";
import SubHeadings from "../components/SubHeadings";
import { addCard, updateCard } from "../redux/action/cardAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router";
import ErrorMessage from "../components/form/ErrorMessage";

const EditPaymentMethod = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cardDetails = useLocation()?.state?.data ?? {};
  const { isLoading } = useSelector((state) => state?.cardReducer);
  const initialState = {
    card_holder_name: cardDetails?.card_holder_name ?? "",
    card_number: cardDetails?.card_number ?? "",
    card_expiry: cardDetails?.card_expiry ?? "",
    card_cvv: cardDetails?.card_cvv ?? "",
  };

  const handleAddCard = async (values) => {
    const data = {
      ...values,
      card_id: cardDetails?.id ?? "",
    };
    const callback = (response) => {
      if (response.success) navigate(pageRoutes?.payment_method);
    };
    dispatch(updateCard({ payload: data, callback }));
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div
                      className="col-md-10 mx-auto"
                      data-aos="fade-down"
                      data-aos-duration="1000"
                    >
                      <div className="ct_profile_form 0">
                        <div className="row">
                          <div className="col-md-8 mx-auto">
                            <div className="d-flex align-items-center justify-content-between mb-4">
                              <h4 className="ct_fs_24 ct_fw_600 mb-0">
                                Payment Methods
                              </h4>
                            </div>
                            <div className="ct_profile_form pb-5">
                              <Formik
                                initialValues={initialState}
                                validationSchema={addPaymentSchema}
                                onSubmit={(values, actions) => {
                                  handleAddCard(values, actions);
                                }}
                              >
                                {({
                                  values,
                                  errors,
                                  touched,
                                  handleChange,
                                  handleBlur,
                                  handleSubmit,
                                }) => (
                                  <form onSubmit={handleSubmit}>
                                    <div className="row">
                                      <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="mb-2 ct_fw_500 ct_fs_16"
                                          >
                                            Card Holder Name<span>*</span>
                                          </label>
                                          <Input
                                            placeholder="card holder name"
                                            id="card_holder_name"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.card_holder_name}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            touched={touched}
                                            fieldName="card_holder_name"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-12 mb-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="mb-2 ct_fw_500 ct_fs_16"
                                          >
                                            Card Number<span>*</span>
                                          </label>
                                          <div className="position-relative">
                                            <Input
                                              placeholder="card number"
                                              id="card_number"
                                              onChange={handleChange}
                                              onBlur={handleBlur}
                                              value={values.card_number}
                                                className="ct_text_indent_30"
                                            />

                                            <i className="fa-regular fa-credit-card ct_payment_card_icon"></i>
                                           
                                          </div>
                                          <ErrorMessage
                                              errors={errors}
                                              touched={touched}
                                              fieldName="card_number"
                                            />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="mb-2 ct_fw_500 ct_fs_16"
                                          >
                                            Expiry Date<span>*</span>
                                          </label>
                                          <Input
                                            type="date"
                                            placeholder="card number"
                                            id="card_expiry"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.card_expiry}
                                            min={
                                              new Date()
                                                .toISOString()
                                                .split("T")[0]
                                            }
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            touched={touched}
                                            fieldName="card_expiry"
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-6 mb-3">
                                        <div className="form-group">
                                          <label
                                            htmlFor=""
                                            className="mb-2 ct_fw_500 ct_fs_16"
                                          >
                                            CVV<span>*</span>
                                          </label>
                                          <Input
                                            placeholder="cvv number"
                                            id="card_cvv"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.card_cvv}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            touched={touched}
                                            fieldName="card_cvv"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                    <div className=" mt-4">
                                      <a
                                        href="javascript:void(0)"
                                        className="ct_green_btn ct_py_6 ct_fs_16"
                                        onClick={handleSubmit}
                                      >
                                        Update Card
                                      </a>
                                    </div>
                                  </form>
                                )}
                              </Formik>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default EditPaymentMethod;
