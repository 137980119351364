import React from "react";
import { Routes, Route } from "react-router-dom";
import PageNotFound from "./components/other/PageNotFound";
import PrivateRoute from "./layout/PrivateRoute";
import { AllRoutes, pageRoutes } from "./routes/pageRoutes";

function App() {
  return (
    <Routes>
      {AllRoutes?.map((item,index) => {
        return (
          <Route
          key={index}
          exact
            path={item.path}
        
            element={
              item?.isPrivate ? (
                <PrivateRoute>{item?.element}</PrivateRoute>
              ) : (
                item?.element
              )
            }
          />
        );
      })}
      <Route exact path="*" element={<PageNotFound />} />
    </Routes>
  );
}

export default App;
