import { createSlice } from "@reduxjs/toolkit";
import {
  addCard,
  confirmBooking,
  deleteCard,
  fetchCardList,
  selectCardDetail,
  updateCard,
} from "../action/cardAction";

const initialState = {
  isLoading: false,
  cards: [],
};

export const cardSlice = createSlice({
  name: "Card",
  initialState: initialState,
  reducers: {
    toggleChange: (state, action) => {
      state.isToggle = action?.payload;
    },
    toggleIsLoading: (state, action) => {
      state.isLoading = action?.payload;
    },
  },
  extraReducers: (builder) => {
    // add-card
    builder.addCase(addCard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(addCard.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(addCard.rejected, (state, action) => {
      state.isLoading = false;
    });
    // update-card
    builder.addCase(updateCard.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(updateCard.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(updateCard.rejected, (state, action) => {
      state.isLoading = false;
    });

    // fetch-card-list
    builder.addCase(fetchCardList.pending, (state, action) => {
      state.isLoading = true;
    });
    builder.addCase(fetchCardList.fulfilled, (state, action) => {
      const { data, success } = action?.payload ?? {};
      state.cards = data ?? [];
      state.isLoading = false;
    });
    builder.addCase(fetchCardList.rejected, (state, action) => {
      state.isLoading = false;
    });

    // delete-card
    builder.addCase(deleteCard.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCard.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(deleteCard.pending, (state, action) => {
      state.isLoading = true;
    });

    // select-card-detail
    builder.addCase(selectCardDetail.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(selectCardDetail.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(selectCardDetail.pending, (state, action) => {
      state.isLoading = true;
    });

    // confirm-booking
    builder.addCase(confirmBooking.fulfilled, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(confirmBooking.rejected, (state, action) => {
      state.isLoading = false;
    });
    builder.addCase(confirmBooking.pending, (state, action) => {
      state.isLoading = true;
    });
  },
});

export const { toggleChange,toggleIsLoading } = cardSlice.actions;
export default cardSlice.reducer;
