import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import SubHeadings from "../components/SubHeadings";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import NoRecord from "../components/other/NoRecord";
import { fetchTripList } from "../redux/action/tripAction";
import Input from "../components/form/Input";
import { curSym, getSubstring, pipViewDate } from "../utils/pip";
import Loader from "../components/other/Loader";

const MyTripHistory = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const list = useSelector((state) => state?.tripReducer?.tripList);
  const { isLoading } = useSelector((state) => state?.tripReducer);
  const [tripList, setTripList] = useState(list);
  const [statusFilter, setStatusFilter] = useState("");

  useEffect(() => {
    let filtered = [...list];

    if (statusFilter) {
      filtered = filtered.filter((sub) => {
        return sub?.pick_up_location
          ?.toLowerCase()
          ?.includes(statusFilter?.toLowerCase());
      });
    }
    setTripList(filtered);
  }, [statusFilter, list]);

  useEffect(() => {
    dispatch(fetchTripList());
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      <Headers />
      <section className="mt-4">
        <SubHeadings />
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <div className="ct_mt_32">
                <div>
                  <div className="row">
                    <div className="col-md-10 mx-auto">
                      <div className="ct_profile_form pb-5">
                        <h4 className="ct_fs_24 ct_fw_600 mb-3">
                          My Trip History
                        </h4>
                        <div className="d-flex align-items-center justify-content-between mb-4 flex-wrap gap-3">
                          <div>
                            <div className="position-relative">
                              <Input
                                placeholder="Search by pick-up location"
                                isPlaceHolder={true}
                                className={"ct_text_indent_30 ct_search_input"}
                                onChange={(e) =>
                                  setStatusFilter(e.target.value)
                                }
                                value={statusFilter}
                              />

                              <i className="fa-solid fa-magnifying-glass ct_search_icon"></i>
                            </div>
                          </div>
                          {/* <button className="ct_green_btn py-2 ct_fs_16 px-4">
                            SEARCH
                          </button> */}
                        </div>
                        <div className="table-responsive">
                          <table className="table ct_custom_table ct_table_td_w_150">
                            <thead>
                              <tr>
                                <th>S. No.</th>
                                <th>Booking Id</th>
                                <th>Booking Date</th>
                                <th>Pick-up Location</th>
                                <th>Destination</th>
                                <th>Duration (Hr/Min)</th>
                                <th>Type</th>
                                <th>Cost</th>
                                <th>Actions</th>
                              </tr>
                            </thead>

                            {tripList?.length > 0 && (
                              <>
                                <tbody>
                                  {tripList?.map((item, index) => (
                                    <tr>
                                      <td>{index + 1}</td>
                                      <td>#{item?.reservation_number}</td>
                                      <td>
                                        {pipViewDate(item?.pickup_date_time)}
                                      </td>
                                      <td>
                                        {item?.pick_up_location
                                          ? getSubstring(
                                              item?.pick_up_location,
                                              10
                                            )
                                          : "N/A"}{" "}
                                      </td>
                                      <td>
                                        {item?.designation
                                          ? getSubstring(item?.designation, 10)
                                          : "N/A"}{" "}
                                      </td>
                                      <td>
                                        {item?.hourly_duration
                                          ? item?.hourly_duration
                                          : "N/A"}{" "}
                                      </td>
                                      <td>{item?.service_type} </td>
                                      <td>
                                        {curSym}
                                        {item?.total_cost}{" "}
                                      </td>
                                      <td>
                                        <a
                                          href="javascript:void(0)"
                                          onClick={() =>
                                            navigate(pageRoutes.trip_details, {
                                              state: { id: item?.id },
                                            })
                                          }
                                          className="ct_green_btn ct_fs_16 py-2 ct_out_line_btn  d-block ct_fit_btn"
                                        >
                                          See Details
                                        </a>
                                      </td>
                                    </tr>
                                  ))}
                                </tbody>
                              </>
                            )}
                          </table>
                          {tripList?.length <= 0 && <NoRecord />}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default MyTripHistory;
