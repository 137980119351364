import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import Footer from "../components/Footer";
import Headers from "../components/Headers";
import { pageRoutes } from "../routes/pageRoutes";
import { useDispatch, useSelector } from "react-redux";
import { confirmBooking, fetchCardList } from "../redux/action/cardAction";
import {
  curSym,
  getBookingData,
  pipErrorMessage,
  pipGetAccessToken,
  pipGetProfile,
  pipRideTimeView,
} from "../utils/pip";
import { fetchTripDetail } from "../redux/action/tripAction";

import { payments } from "@square/web-sdk";
import {
  APPLICATION_ID,
  BASE_URL_Payment,
  LOCATION_ID,
  squarePaymentAPI,
} from "../routes/apiEndPoints";
import { message } from "antd";
import Loader from "../components/other/Loader";
import { toggleIsLoading } from "../redux/reducers/cardReducer";

const SelectDestination = () => {
  const typeofLogin = localStorage?.getItem("typeofLogin");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const localTripDetail = getBookingData();
  const [active, setActive] = useState(true);
  const { isLoading, cards } = useSelector((state) => state.cardReducer);
  const { tripDetail } = useSelector((state) => state.tripReducer);
  const [prevCardId, setPrevCardId] = useState();
  const initialState = {
    card_holder_name: "",
    card_number: "",
    card_expiry: "",
    card_cvv: "",
  };

  useEffect(() => {
    // dispatch(fetchCardList());
    dispatch(fetchTripDetail(localTripDetail?.id));
  }, []);

  const handleBookNow = (responseData, paymentToken) => {
    const callback = (response) => {
      if (response.success) {
        localStorage?.removeItem("booking-data");
        navigate(pageRoutes.my_trip_history);
      }
    };
    dispatch(
      confirmBooking({
        payload: {
          payment_id: responseData?.id,
          sourceId: paymentToken,
          booking_id: tripDetail?.id,
          user_id: pipGetProfile()?.id,
        },
        callback,
      })
    );
  };

  useEffect(() => {
    if (prevCardId) {
    }
  }, [prevCardId]);

  // New Production

  const [card, setCard] = useState(null);

  async function initializeSquarePayment() {
    try {
      // const paymentInstance = await payments(APPLICATION_ID, "production");
      const paymentInstance = await payments(APPLICATION_ID, "sandbox");

      if (!paymentInstance) {
        message?.error("Failed to initialize payment instance");
        return;
      }

      const cardInstance = await paymentInstance.card();
      if (!cardInstance) {
        message?.error("Failed to initialize card");
        return;
      }
      await cardInstance.attach("#card-container");
      setCard(cardInstance);
    } catch (error) {
      console.error("Square Payment initialization error:", error);
    }
  }

  useEffect(() => {
   
    initializeSquarePayment();
  }, []);

  const handlePayment = async () => {
    if (!card) {
      message?.error("Card not initialized");
      return;
    }
    dispatch(toggleIsLoading(true));
    try {
      const result = await card.tokenize();
      if (result.status === "OK") {
        const paymentToken = result.token;
        const verificationToken = await verifyBuyer(paymentToken);
        dispatch(toggleIsLoading(false));
        await processPaymentOnServer(paymentToken, verificationToken);
      } else {
        dispatch(toggleIsLoading(false));
        message?.error("Payment failed. Please try again.");
        initializeSquarePayment();
       
      }
    } catch (error) {
      dispatch(toggleIsLoading(false));
      console.error("Tokenization error:", error);
    }
  };

  const verifyBuyer = async (paymentToken) => {
    try {
      const verificationDetails = {
        amount: parseFloat(tripDetail?.total_cost_show_only || 0).toFixed(2),
        billingContact: {
          givenName: "John",
          familyName: "Doe",
          email: "john.doe@example.com",
          phone: "1234567890",
          addressLines: ["123 Main Street", "Apartment 1"],
          city: "Anytown",
          state: "CA",
          countryCode: "US",
        },
        currencyCode: tripDetail?.currency || "USD",
        intent: "CHARGE",
      };

      const verificationResults = await payments.verifyBuyer(
        paymentToken,
        verificationDetails
      );
      return verificationResults.token;
    } catch (error) {
      console.error("Buyer verification failed:", error);
    }
  };

  const processPaymentOnServer = async (paymentToken) => {
    dispatch(toggleIsLoading(true));
    try {
      const response = await fetch(BASE_URL_Payment + squarePaymentAPI, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          locationId: LOCATION_ID,
          sourceId: paymentToken,
          amount: (tripDetail?.additional_cost != "0.00" ||
            tripDetail?.additional_cost != 0) ? tripDetail?.total_cost : tripDetail?.total_cost_show_only,
        }),
      });

      if (!response.ok) {
        const errorText = await response.text();
        message?.error(JSON.parse(errorText)?.message);
        dispatch(toggleIsLoading(false));
        throw new Error(errorText);
      }

      const responseData = await response.json();
      if (responseData.success) {
        dispatch(toggleIsLoading(false));
        handleBookNow(responseData?.data, paymentToken);
      } else {
        dispatch(toggleIsLoading(false));
        message?.error("Payment failed");
      }
    } catch (error) {
      dispatch(toggleIsLoading(false));
      console.error("Server error:", error);
    }
  };

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Headers />
      <div className="ct_light_grey_bg">
        <div className="container">
          <div className="row">
            <div
              className="col-md-12"
              data-aos="fade-down"
              data-aos-duration="1000"
            >
              <p className="mb-1 ct_fs_14 ct_fw_500">
                STEP {typeofLogin ? "3" : "4"} OF {typeofLogin ? "3" : "4"}
              </p>
              <div className="d-flex align-items-center gap-2 justify-content-between ct_flex_column_767">
                <h4 className="mb-0 ct_fs_24 ct_fw_500 mb-3 mb-md-0">
                  Payment Information
                </h4>
                <ul className="d-flex align-items-center gap-4 flex-wrap">
                  <li className="d-flex align-items-center gap-2">
                    <div className="ct_circle_24 ct_active_check">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <p className="mb-0 ct_fs_14 ct_fw_500">Ride Information</p>
                  </li>
                  <li className="d-flex align-items-center gap-2">
                    <div className="ct_circle_24 ct_active_check">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <p className="mb-0 ct_fs_14 ct_fw_500">Vehicle Type</p>
                  </li>
                  {!typeofLogin && (
                    <li className="d-flex align-items-center gap-2">
                      <div className="ct_circle_24 ct_active_check">
                        <i className="fa-solid fa-check"></i>
                      </div>
                      <p className="mb-0 ct_fs_14 ct_fw_500">Login</p>
                    </li>
                  )}
                  <li className="d-flex align-items-center gap-2">
                    <div className="ct_circle_24 ">
                      <i className="fa-solid fa-check"></i>
                    </div>
                    <p className="mb-0 ct_fs_14 ct_fw_500 ">Payment</p>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="ct_mt_32">
              <div>
                <div
                  className="ct_grid_90_auto mb-5"
                  data-aos="fade-down"
                  data-aos-duration="1000"
                >
                  {/* <div className="row mt-4">
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Pickup Location
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          Miami, FL, USA
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt ct_border_left_1">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Destination
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          Key West, FL 33040, USA
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt ct_border_left_1">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Date & Time
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          wed, sep 4th, 2024 12:00 AM
                        </h5>
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt ct_border_left_1">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Car type
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          Luxury Sedan
                        </h5>
                      </div>
                    </div>
                  </div> */}
                  {/*Not Return Pickut Details */}

                  <div className="row mt-5">
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Pickup Location
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          {tripDetail?.pick_up_location}
                        </h5>
                      </div>
                    </div>
                    {tripDetail?.service_type == "hourly" ? (
                      <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <div className="trip_details_cnt ct_border_left_1">
                          <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                            Duration
                          </span>
                          <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                            {tripDetail?.hourly_duration}
                          </h5>
                        </div>
                      </div>
                    ) : (
                      <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                        <div className="trip_details_cnt ct_border_left_1">
                          <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                            Destination
                          </span>
                          <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                            {tripDetail?.designation}
                          </h5>
                        </div>
                      </div>
                    )}
                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt ct_border_left_1">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Date & Time
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          {pipRideTimeView(tripDetail?.pickup_date_time)}
                        </h5>
                      </div>
                    </div>

                    <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                      <div className="trip_details_cnt ct_border_left_1">
                        <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                          Car Name
                        </span>
                        <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                          {tripDetail?.car?.car_type}
                        </h5>
                      </div>
                    </div>
                  </div>
                  {tripDetail?.is_return && (
                    <>
                      <button
                        className="ct_grey_btn my-4"
                        style={{ cursor: "text" }}
                      >
                        <svg
                          width="19"
                          height="24"
                          viewBox="0 0 19 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.6429 18.68V9.33333H12.0714V18.68H8.21429L13.3571 24L18.5 18.68H14.6429ZM5.64286 0L0.5 5.32H4.35714V14.6667H6.92857V5.32H10.7857L5.64286 0Z"
                            fill="#004225"
                            fillOpacity="0.54"
                          />
                        </svg>{" "}
                        Return trip
                      </button>
                      <div className="row">
                        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                          <div className="trip_details_cnt">
                            <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                              Pickup Location
                            </span>
                            <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                              {tripDetail?.designation}
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                          <div className="trip_details_cnt ct_border_left_1">
                            <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                              Destination
                            </span>
                            <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                              {tripDetail?.pick_up_location}
                            </h5>
                          </div>
                        </div>
                        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                          <div className="trip_details_cnt ct_border_left_1">
                            <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                              Date & Time
                            </span>
                            <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                              {pipRideTimeView(
                                tripDetail?.return_pickup_date_time
                              )}
                            </h5>
                          </div>
                        </div>

                        <div className="col-lg-3 col-md-6 mb-4 mb-lg-0">
                          <div className="trip_details_cnt ct_border_left_1">
                            <span className="ct_fs_14 ct_ff_poppins ct_text_dark_green ct_fw_500 d-block mb-2">
                              Car Name
                            </span>
                            <h5 className="ct_fs_18 ct_ff_poppins ct_fw_500 ct_text_clr_545454">
                              {tripDetail?.car?.car_type}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                  <div
                    onClick={() =>
                      navigate(pageRoutes.dashboard, {
                        state: { isEdit: true },
                      })
                    }
                  >
                    <a
                      href="javascript:void(0)"
                      className="ct_green_btn py-2 ct_fs_16 ct_btn_w_125 d-block text-center"
                    >
                      Edit
                    </a>
                  </div>
                </div>
                <div className="row  pb-5">
                  <div
                    className="col-lg-7 mb-4 mb-lg-0"
                    data-aos="fade-right"
                    data-aos-duration="1000"
                  >
                    <div className="ct_profile_form">
                      <div className="mb-4">
                        <h4 className="ct_fs_24 ct_fw_600 ">
                          Payment Information
                        </h4>
                        <p className="mb-0 ct_text_op_5">
                          All transactions are secure and encrypted. Safe and
                          secure payments powered by Square
                        </p>
                      </div>
                      <div>
                        <div id="card-container"></div>
                      </div>

                      {/* <>
                      <div className="mb-4">
                        <label htmlFor="ct_previous_card">
                          <input
                            type="radio"
                            name="cardselection"
                            id="ct_previous_card"
                            checked={active === true}
                            onChange={() => setActive(true)} // Set state to true when selecting previous cards
                          />{" "}
                          Select from previous cards
                        </label>
                        <label htmlFor="ct_add_new_card" className="ms-4">
                          <input
                            type="radio"
                            name="cardselection"
                            id="ct_add_new_card"
                            checked={active === false}
                            onChange={() => setActive(false)} // Set state to false when adding a new card
                          />{" "}
                          Add a new card
                        </label>
                      </div>
                      {active && (
                        <div className="table-responsive">
                          <table className="table ct_custom_table">
                            <thead>
                              <tr>
                                <th></th>
                                <th>Card Holder Name</th>
                                <th>Exp. Date</th>
                                <th className="text-end">Card Number</th>
                              </tr>
                            </thead>
                            {cards?.length > 0 && (
                              <tbody>
                                {cards?.map((item, index) => (
                                  <tr>
                                    <td>
                                      <input
                                        type="radio"
                                        name="cardselect"
                                        onChange={() => setPrevCardId(item.id)} // Set selected card ID
                                        checked={prevCardId === item.id}
                                      />
                                    </td>
                                    <td>{item?.card_holder_name}</td>
                                    <td>
                                      {pipCardDateView(item?.card_expiry)}
                                    </td>
                                    <td className="text-end">
                                      {item?.card_number}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            )}
                          </table>
                          {!cards?.length > 0 && <NoRecord />}
                        </div>
                      )}
                      {!active && (
                        <Formik
                          initialValues={initialState}
                          validationSchema={addPaymentSchema}
                          onSubmit={(values, actions) => {
                            handleAddCard(values, actions);
                          }}
                        >
                          {({
                            values,
                            errors,
                            touched,
                            handleChange,
                            handleBlur,
                            handleSubmit,
                          }) => (
                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Card Holder Name <span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="card holder name"
                                        id="card_holder_name"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.card_holder_name}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="card_holder_name"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className="col-md-12 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Card Number<span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="card number"
                                        id="card_number"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        className="ct_text_indent_30"
                                        value={values.card_number}
                                      />

                                      <i className="fa-regular fa-credit-card ct_payment_card_icon"></i>
                                    </div>
                                    <ErrorMessage
                                      errors={errors}
                                      touched={touched}
                                      fieldName="card_number"
                                    />
                                  </div>
                                </div>

                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      Expiry Date <span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        type="date"
                                        placeholder="card number"
                                        id="card_expiry"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.card_expiry}
                                        min={
                                          new Date().toISOString().split("T")[0]
                                        }
                                      />
                                      <ErrorMessage
                                      
                                        errors={errors}
                                        touched={touched}
                                        fieldName="card_expiry"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div className="col-md-6 mb-3">
                                  <div className="form-group">
                                    <label
                                      htmlFor=""
                                      className="mb-2 ct_fw_500 ct_fs_16"
                                    >
                                      CVV <span>*</span>
                                    </label>
                                    <div className="position-relative">
                                      <Input
                                        placeholder="cvv number"
                                        id="card_cvv"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.card_cvv}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        touched={touched}
                                        fieldName="card_cvv"
                                      />
                                    </div>
                                  </div>
                                </div>

                                <div className=" mt-3 mb-4">
                                  <a
                                    href="javascript:void(0)"
                                    className="ct_green_btn ct_hover_white  ct_py_6 "
                                    onClick={handleSubmit}
                                  >
                                    Save
                                  </a>
                                </div>
                              </div>

                              <div className="d-flex align-items-center gap-1 ct_payment__icons">
                                <img src="../assets/img/visa.png" alt="/" />
                                <img src="../assets/img/master_card.png" alt="" />
                                <img src="../assets/img/nissan.png" alt="" />
                                <img src="../assets/img/payment_card.png" alt="" />
                                <img src="../assets/img/american.png" alt="" />
                              </div>
                            </form>
                          )}
                        </Formik>
                      )}
</> */}
                    </div>
                  </div>
                  <div
                    className="col-lg-5 mb-4 mb-lg-0 "
                    data-aos="fade-left"
                    data-aos-duration="1000"
                  >
                    <div className=" h-100">
                      <div className="ct_right_login_small   ">
                        <div className="ct_profile_form pb-5">
                          <div className="d-flex align-items-center flex-wrap gap-3 justify-content-between">
                            <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                              Total
                            </h4>
                            <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                              {`${curSym} 
                              ${tripDetail?.total_cost_show_only}`}{" "}
                              USD
                            </h4>
                          </div>
                          {(tripDetail?.additional_cost != "0.00" ||
                            tripDetail?.additional_cost != 0) && (
                            <>
                              <div className="d-flex align-items-center flex-wrap gap-3 justify-content-between">
                                <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                                  Previous Ride Amount
                                </h4>
                                <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                                  -{" "}
                                  {`${curSym} 
                              ${
                                parseFloat(tripDetail?.total_cost)
                              }`}{" "}
                                  USD
                                </h4>
                              </div>
                              <div className="d-flex align-items-center flex-wrap gap-3 justify-content-between">
                                <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                                  Balance Amount to Pay
                                </h4>
                                <h4 className="ct_fs_18 ct_fw_600 mb-3 ct_text_dark_green">
                                  {`${curSym} 
                              ${tripDetail?.additional_cost}`}{" "}
                                  USD
                                </h4>
                              </div>
                            </>
                          )}
                          <div className="mt-0">
                            <button
                              className="ct_green_btn w-100  ct_fw_500 ct_fs_14"
                              // onClick={() => handleBookNow()}
                              onClick={handlePayment}
                            >
                              {/* BOOK NOW */}
                              Submit Payment
                            </button>
                          </div>
                          <p style={{ color: "#838383" }} className="ct_mt_20">
                            By clicking "BOOK NOW", you agree to our{" "}
                            <a
                              href="javascript:void(0)"
                              className="ct_text_dark_green ct_text_decoration_underline"
                            >
                              Terms & Conditions
                            </a>
                          </p>
                          <div className="mt-4 ct_stripe_icon">
                            <img
                              src="../assets/img/PoweredbySquare.svg"
                              alt=""
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default SelectDestination;
